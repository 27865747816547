import { CrudService } from "nest-utilities-client";
export const energyPriceRequestService = new (class EnergyPriceRequestService extends CrudService {
    constructor() {
        super(process.env.API_URL + "energy-price-request");
    }
    getPrice(energyRequestId) {
        return this.http.get([
            process.env.API_URL +
                "energy-price-request/" +
                energyRequestId +
                "/energy-price"
        ].join("/"));
    }
})();
