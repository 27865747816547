import React, { useRef } from "react";
import { style } from "typestyle";
import { Dimensions } from "../../styling/Dimensions";
const classNames = {
    innerContainer: style({
        boxSizing: "border-box",
        position: "relative",
        width: "100%",
        maxWidth: Dimensions.maxUIWidth,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 0,
        padding: `${Dimensions.padding * 2}px ${Dimensions.padding * 4}px`,
        $debugName: "inner-container",
        $nest: {
            "@media screen and (max-width: 900px)": {
                padding: `${Dimensions.padding}px ${Dimensions.padding * 2}px`
            }
        }
    }),
    belowNavBar: style({
        marginTop: Dimensions.navBarHeight
    })
};
export const PageInnerContainer = (props) => {
    const innerClassNames = useRef([
        classNames.innerContainer,
        props.className,
        props.isTopContent === true ? classNames.belowNavBar : ""
    ].join(" "));
    return React.createElement("div", { className: innerClassNames.current }, props.children);
};
