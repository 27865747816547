import { HousingType } from "../enums/housingType.enum";
import { SolarPanelOrientation } from "../enums/solarPanelOrientation.enum";
export const calculatorUtils = new (class CalculatorUtils {
    constructor() {
        // gas price
        this.gasPrice = 0.05;
        this.greenSwap = 0.195;
        this.productionPrice = 0.08;
        this.discountGasPrice = 0.08;
        this.electricityDeliveryCosts = 30;
        this.gasDeliveryCosts = 30;
        // Belasting & ODE Electriciteit voor 2021
        this.electrictyTax10K = 0.0977;
        this.electricityTax50k = 0.05083;
        this.electricityTax10m = 0.01353;
        this.electricityOde10kCosts = 0.03;
        this.electricityOde50kCosts = 0.0411;
        this.electricityOde10mCosts = 0.0225;
        this.gasTax170kCosts = 0.3485;
        this.gasTax1mCosts = 0.06444;
        this.gasOde170kCosts = 0.0851;
        this.gasOde1mCosts = 0.0235;
        // vaste heffingen
        this.networkElectricityCosts = 232.32;
        this.networkGasCosts = 176.91;
        this.taxReturn = 558.17;
        this.solarProductionPrice = 0.08;
        this.averageSolarHours = 1100;
        // kwh based on amount of persons living in a household
        this.averageKwhByResidence = new Map([
            [1, 1925],
            [2, 3005],
            [3, 3605],
            [4, 4155],
            [5, 4375],
            [6, 4385]
        ]);
        this.averageGasByHousingType = new Map([
            [HousingType.FLAT, 1000],
            [HousingType.TUSSENWONING, 1350],
            [HousingType.HOEKWONING, 1580],
            [HousingType.TWEE_ONDER_EEN_KAP, 1800],
            [HousingType.VRIJSTAAND, 2410]
        ]);
        this.effectivityByOrientation = new Map([
            [SolarPanelOrientation.ZUID, 0.9],
            [SolarPanelOrientation.ZUID_WEST, 0.88],
            [SolarPanelOrientation.ZUID_OOST, 0.88],
            [SolarPanelOrientation.WEST, 0.8],
            [SolarPanelOrientation.OOST, 0.8],
            [SolarPanelOrientation.OOST_WEST, 0.82],
            [SolarPanelOrientation.NOORD_WEST, 0.78],
            [SolarPanelOrientation.NOORD_OOST, 0.78],
            [SolarPanelOrientation.NOORD, 0.7]
        ]);
    }
    calculateYearlyPrice(energyPrice) {
        if (!energyPrice.gasYearlyPrice)
            energyPrice.gasYearlyPrice = 0;
        if (!energyPrice.fixedCostsYearlyPrice)
            energyPrice.fixedCostsYearlyPrice = 0;
        if (!energyPrice.solarCashbackYearlyPrice)
            energyPrice.solarCashbackYearlyPrice = 0;
        if (!energyPrice.solarReturnYearlyPrice)
            energyPrice.solarReturnYearlyPrice = 0;
        const total = energyPrice.electricityYearlyPrice +
            energyPrice.gasYearlyPrice +
            energyPrice.fixedCostsYearlyPrice;
        const pricePerYear = total -
            (energyPrice.solarReturnYearlyPrice +
                energyPrice.solarCashbackYearlyPrice);
        return pricePerYear;
    }
    getPricePerKwh(lowKwhRate, highKwhRate) {
        if (lowKwhRate && highKwhRate) {
            const priceKwhLow = 0.05;
            const priceKwhHigh = 0.07;
            const kwhLow = (lowKwhRate / (lowKwhRate + highKwhRate)) * priceKwhLow;
            const kwhHigh = (highKwhRate / (lowKwhRate + highKwhRate)) * priceKwhHigh;
            return kwhLow + kwhHigh;
        }
        return 0.07;
    }
    // if we need to calculate the price for the client
    getAveragePricePerMonth(request) {
        const totalKwh = this.averageKwhByResidence.get(request.residenceAmount);
        let totalGasCosts = 0;
        let totalFixedCosts = this.getFixedCosts(request.electricityOnly);
        if (request.electricityOnly === false) {
            const totalGasSquareM = this.averageGasByHousingType.get(request.housingType);
            totalGasCosts = this.getGasPrice(totalGasSquareM);
        }
        const electricityTotalCosts = this.getElectricityPriceWithoutSolarPanels(null, null, totalKwh);
        // no solar calculations needed over here
        return +((totalGasCosts + electricityTotalCosts + totalFixedCosts) /
            12).toFixed(2);
    }
    // if the calculate already knows must of the values
    getCustomPricePerMonth(request) {
        const lowKwhRate = request.lowKwhRate;
        const normalKwhRate = request.normalKwhRate;
        const highKwhRate = request.highKwhRate;
        const totalSolarKwh = request.solarLowKwh + request.solarHighKwh;
        const electricityTotal = totalSolarKwh > 0
            ? this.getElectricityPriceWithSolarPanels(lowKwhRate, highKwhRate, normalKwhRate)
            : this.getElectricityPriceWithoutSolarPanels(lowKwhRate, highKwhRate, normalKwhRate);
        // if the client only wants gas, set gas price to zero
        const gasTotal = request.electricityOnly === false
            ? this.getGasPrice(request.gasInCubicMeters)
            : 0;
        const fixedCosts = this.getFixedCosts(request.electricityOnly);
        const solarReturn = this.getSolarReturnPrice(totalSolarKwh) || 0;
        const efficiency = this.effectivityByOrientation.get(request.solarPanelOrientation);
        const totalSolarWatts = request.solarPanelAmount * request.solarPanelWattsPerPanel;
        const solarCashBack = this.getSolarCashBack(totalSolarWatts, efficiency, request.solarPanelYear) || 0;
        const total = electricityTotal + gasTotal + fixedCosts;
        const pricePerYear = total - (solarReturn + solarCashBack);
        return pricePerYear / 12;
    }
    getGasPrice(gasCubicMeter) {
        if (!gasCubicMeter)
            return 0;
        const tax = 1.21;
        const gasTax = this.getGasTaxes(gasCubicMeter);
        const gasTaxesPerCubicM = gasTax / gasCubicMeter;
        const finalGasPrice = (this.gasPrice + gasTaxesPerCubicM) * tax * (1 - this.discountGasPrice);
        return this.gasDeliveryCosts + gasCubicMeter * finalGasPrice;
    }
    // if client does not have solar panels then price is 70% normal price
    // (ex VAT) and 70% groen ruil (inc VAT)
    getElectricityPriceWithoutSolarPanels(lowKwhRate, highKwhRate, normalKwhRate) {
        const lowOrNormal = lowKwhRate ? lowKwhRate : normalKwhRate;
        const pricePerKwh = this.getPricePerKwh(lowOrNormal, highKwhRate);
        const kwhTotal = lowKwhRate ? lowKwhRate + highKwhRate : normalKwhRate;
        // get the lowKwhRate that the user filled in
        const electricityTax = this.getElectrictyTax(kwhTotal);
        const greenTradeTotal = 0.3 * kwhTotal * this.greenSwap;
        //TODO:: Error in totalElectricityPrice!!
        // purchase price + tax
        const totalElectricityPrice = 0.7 * kwhTotal * ((pricePerKwh + electricityTax / kwhTotal) * 1.21);
        return (this.electricityDeliveryCosts + greenTradeTotal + totalElectricityPrice);
    }
    // if client has solar panels, price is 100% normal price (ex VAT)
    getElectricityPriceWithSolarPanels(lowKwhRate, highKwhRate, normalKwhRate) {
        const pricePerKwh = this.getPricePerKwh(lowKwhRate, highKwhRate);
        const totalKwh = lowKwhRate ? lowKwhRate + highKwhRate : normalKwhRate;
        const electrictyTaxes = this.getElectrictyTax(totalKwh);
        const totalElectricityPrice = totalKwh * ((pricePerKwh + electrictyTaxes / totalKwh) * 1.21);
        return this.electricityDeliveryCosts + totalElectricityPrice;
    }
    getFixedCosts(electrictyOnly) {
        // request price for gas and electricity
        if (electrictyOnly === false) {
            return (this.networkGasCosts + this.networkElectricityCosts - this.taxReturn);
        }
        else {
            return this.networkElectricityCosts - this.taxReturn;
        }
    }
    //Get the gas taxes
    getGasTaxes(gas) {
        if (gas < 170000) {
            return gas * (this.gasTax170kCosts + this.gasOde170kCosts);
        }
        else {
            return (gas - 170000) * (this.gasTax1mCosts + this.gasOde1mCosts) + 73712;
        }
    }
    // the electricty taxes
    getElectrictyTax(kwh) {
        if (kwh < 10000) {
            return kwh * (this.electrictyTax10K + this.electricityOde10kCosts);
        }
        else if (kwh >= 10000 && kwh < 50000) {
            return (1276.872 +
                (kwh - 10000) * (this.electricityTax50k + this.electricityOde50kCosts));
            // Tax + ODE to pay if kwh is > 50k
        }
        else {
            return (4953.98 +
                (kwh - 49999) * (this.electricityTax10m + this.electricityOde10mCosts));
        }
    }
    // returns how much the client will get in return for his solar panels
    getSolarReturnPrice(solarPanelKwh) {
        if (!solarPanelKwh)
            return 0;
        return solarPanelKwh * this.productionPrice;
    }
    getSolarCashBack(watts, orientationByEfficiency, year) {
        const kwhProduction = this.getSolarKwhProduction(watts, orientationByEfficiency, year);
        const productionPrice = this.getSolarProductionPrice(watts, orientationByEfficiency, year);
        return kwhProduction * productionPrice;
    }
    // Returns the nominal production of the client based on 1000 solar hours.
    getSolarNominalProduction(watts, orientationEfficiency, year) {
        return (watts *
            orientationEfficiency *
            (1 - (new Date().getFullYear() - year) * 0.005));
    }
    getSolarKwhProduction(watts, orientationByEfficiency, year) {
        const nominalProduction = this.getSolarNominalProduction(watts, orientationByEfficiency, year);
        return (nominalProduction * this.averageSolarHours) / 1000;
    }
    getSolarProductionPrice(watts, orientationByEfficiency, year) {
        const nominalProduction = this.getSolarNominalProduction(watts, orientationByEfficiency, year);
        const kwhProduction = this.getSolarKwhProduction(watts, orientationByEfficiency, year);
        return (this.solarProductionPrice * nominalProduction) / kwhProduction;
    }
})();
