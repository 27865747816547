import React from "react";
import { style } from "typestyle";
import { Colors } from "../../styling/Colors";
import { InputLabel } from "./inputLabel.element";
const classNames = {
    container: style({
        $debugName: "container",
        // height: "100%",
        // maxHeight: 50,
        display: "flex",
        flexDirection: "column"
    }),
    inputWrapper: style({
        position: "relative",
        flex: 1
    }),
    input: style({
        cursor: "pointer",
        padding: 10,
        fontSize: "1.125rem",
        border: "1px solid #E5E5E5",
        height: 50,
        borderBottom: `2px solid #E5E5E5`,
        outline: "none",
        minWidth: "150px",
        width: "100%",
        marginRight: 10,
        $nest: {
            "&:hover": {
                borderBottom: `2px solid ${Colors.red}`
            }
        },
        backgroundImage: `url("/svgs/arrow-down.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right 15px center"
    })
};
export const SelectInput = (props) => {
    return (React.createElement("div", { className: [classNames.container, props.className].join(" ") },
        props.showLabel === true && (React.createElement(InputLabel, { required: props.required }, props.name)),
        React.createElement("div", { className: classNames.inputWrapper },
            React.createElement("select", Object.assign({}, props, { className: classNames.input }), props.options.map((option) => (React.createElement("option", { value: option.value, key: option.value }, option.label)))))));
};
