import React, { useRef } from "react";
import { style } from "typestyle";
import { Dimensions } from "../../styling/Dimensions";
import { Button } from "../elements/button.element";
import { Header } from "../elements/header.element";
import { Input } from "../elements/input.element";
import { SubmitButton } from "../elements/submitButton";
const classNames = {
    container: style({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }),
    inputWrappers: style({
        display: "flex",
        padding: `${Dimensions.padding} 0`
    }),
    buttonsWrapper: style({
        display: "flex",
        flexFlow: "wrap"
    }),
    submitButton: style({
        width: 200
    }),
    form: style({
        height: "100%"
    }),
    kwhLowInput: style({
        marginRight: 10
    })
};
export const CalculatorSolarPanelDetailsForm = (props) => {
    const solarPanelKwhLow = useRef(null);
    const solarPanelKwhNormal = useRef(null);
    const solarPanelKwhHigh = useRef(null);
    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSubmit(solarPanelKwhLow.current, solarPanelKwhNormal.current, solarPanelKwhHigh.current);
    };
    return (React.createElement("form", { onSubmit: handleSubmit, id: "solar-panel-details-form", className: classNames.form },
        React.createElement("div", { className: classNames.container },
            React.createElement(Header, { text: "Hoeveel stroom levert u jaarlijks terug?", size: "large", fontWeight: "bold" }),
            React.createElement("div", { className: classNames.inputWrappers },
                props.hasDoubleMeter === true && (React.createElement(React.Fragment, null,
                    React.createElement(Input, { type: "number", name: "solar-panel-low", form: "solar-panel-details-form", placeholder: "kWh laag", onChange: (e) => {
                            solarPanelKwhLow.current = +e.currentTarget.value;
                        }, className: classNames.kwhLowInput, required: true }),
                    React.createElement(Input, { type: "number", name: "solar-panel-high", form: "solar-panel-details-form", placeholder: "kWh hoog", onChange: (e) => {
                            solarPanelKwhHigh.current = +e.currentTarget.value;
                        }, required: true }))),
                props.hasDoubleMeter === false && (React.createElement(Input, { type: "number", name: "solar-panel-normal", form: "solar-panel-details-form", placeholder: "kWh normaal", onChange: (e) => {
                        solarPanelKwhNormal.current = +e.currentTarget.value;
                    }, className: classNames.kwhLowInput, required: true }))),
            React.createElement("div", { className: classNames.buttonsWrapper },
                React.createElement(Button, { text: "Vorige", onClick: () => props.onPreviousClick() }),
                React.createElement(SubmitButton, { className: classNames.submitButton, form: "solar-panel-details-form", value: "Volgende" })))));
};
