var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import { style } from "typestyle";
import { contactService } from "../../services/contact.service";
import Notification from "../../statics/Notification";
import { Dimensions } from "../../styling/Dimensions";
import { ContactInfoBlock } from "../elements/contactInfoBlock";
import { Header } from "../elements/header.element";
import { Page } from "../elements/page.element";
import { PageInnerContainer } from "../elements/pageInner.element";
import { ContactForm } from "../forms/contact.form";
const classNames = {
    header: style({
        height: 140,
        $nest: {
            "@media screen and (max-width: 1000px)": {
                height: 100
            }
        }
    }),
    pageWrapper: style({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        minHeight: `calc(100vh - (${Dimensions.navBarHeight}px + ${Dimensions.footerHeight}px) )`,
        $nest: {
            "@media screen and (max-width: 850px)": {
                flexWrap: "wrap"
            }
        }
    }),
    mainBlock: style({
        $debugName: "mainblock",
        height: "100%",
        width: "60%",
        $nest: {
            "@media screen and (max-width: 1000px)": {
                width: "55%"
            },
            "@media screen and (max-width: 850px)": {
                width: "100%"
            }
        }
    }),
    contactBlock: style({
        height: "100%",
        width: "30%",
        $nest: {
            "@media screen and (max-width: 1000px)": {
                width: "35%"
            },
            "@media screen and (max-width: 850px)": {
                width: "100%",
                padding: `${Dimensions.padding * 4}px 0px`
            }
        }
    }),
    logo: style({
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minWidth: 25,
        minHeight: 25,
        marginRight: 10
    }),
    addressWrapper: style({
        display: "flex",
        flexDirection: "column",
        fontWeight: 500,
        fontSize: "1.125rem"
    }),
    addressLine: style({
        display: "flex",
        marginBottom: 10,
        alignItems: "center"
    }),
    phone: style({
        backgroundImage: `url("/svgs/phone.svg")`
    }),
    mail: style({
        backgroundImage: `url("/svgs/envelope.svg")`
    })
};
export const Contact = (_props) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    return (React.createElement(Page, null,
        React.createElement(PageInnerContainer, { isTopContent: true },
            React.createElement("div", { className: classNames.pageWrapper },
                React.createElement("div", { className: classNames.mainBlock },
                    React.createElement(Header, { text: "Contactformulier", size: "huge", fontWeight: "regular", className: classNames.header }),
                    isSubmitted === false && (React.createElement(ContactForm, { onSubmit: (contact) => __awaiter(void 0, void 0, void 0, function* () {
                            try {
                                yield contactService.post(contact);
                                setIsSubmitted(true);
                                window.scrollTo(0, 0);
                            }
                            catch (e) {
                                Notification.showGenericError();
                            }
                        }) })),
                    isSubmitted && (React.createElement("span", null, "Bedankt, we nemen zo spoedig mogelijk contact met u op!"))),
                React.createElement("div", { className: classNames.contactBlock },
                    React.createElement(Header, { text: "Onze contactgegevens", size: "huge", fontWeight: "regular", className: classNames.header }),
                    React.createElement(ContactInfoBlock, null))))));
};
