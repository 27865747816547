import React, { useCallback, useState } from "react";
import { style } from "typestyle";
const classNames = {
    container: style({
        display: "block",
        position: "relative",
        cursor: "pointer",
        userSelect: "none",
        backgroundColor: "white",
        height: 20,
        width: 20
    }),
    input: style({
        position: "absolute",
        appearance: "checkbox",
        opacity: 0.001,
        height: 20,
        width: 20,
        cursor: "pointer"
    }),
    checkMark: style({
        height: 7,
        width: 12,
        top: 6,
        left: 5,
        border: "3px solid black",
        borderTop: "none",
        borderRight: "none",
        position: "absolute",
        transform: "rotate(-45deg)",
        cursor: "pointer"
    })
};
export const InputCheckbox = (props) => {
    const [isChecked, setIsChecked] = useState(false);
    const toggleChecked = useCallback(() => {
        setIsChecked(!isChecked);
        if (props.onClick)
            props.onClick();
    }, [isChecked]);
    return (React.createElement("div", { className: [classNames.container, props.className].join(" "), onClick: toggleChecked },
        React.createElement("input", { form: props.form, name: props.name, type: "checkbox", required: props.required, defaultChecked: false, className: classNames.input }),
        isChecked && React.createElement("span", { className: classNames.checkMark })));
};
