import React from "react";
import { style } from "typestyle";
import { Dimensions } from "../../styling/Dimensions";
const classNames = {
    container: style({
        position: "relative",
        backgroundColor: "white",
        border: "1px solid #E5E5E5",
        borderBottom: "7px solid #F0613D",
        padding: `${Dimensions.padding * 3}px ${Dimensions.padding * 2}px`,
        color: "black",
        width: "100%"
    })
};
export const InfoBlock = (props) => {
    return (React.createElement("div", { className: [classNames.container, props.className].join(" ") }, props.children));
};
