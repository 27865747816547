import React, { useRef } from "react";
import { style } from "typestyle";
import { Dimensions } from "../../styling/Dimensions";
import { Button } from "../elements/button.element";
import { Header } from "../elements/header.element";
import { Input } from "../elements/input.element";
import { SubmitButton } from "../elements/submitButton";
const classNames = {
    container: style({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }),
    inputWrappers: style({
        display: "flex",
        padding: `${Dimensions.padding} 0`
    }),
    buttonsWrapper: style({
        display: "flex"
    }),
    input: style({
        marginBottom: 10,
        marginRight: 10
    }),
    inputsWrapper: style({
        display: "flex",
        flexFlow: "wrap",
        padding: `${Dimensions.padding} 0`
    }),
    submitButton: style({
        width: 200
    }),
    form: style({
        height: "100%"
    })
};
export const CalculatorYearlyUsageForm = (props) => {
    const handleSubmit = (event) => {
        event.preventDefault();
        const values = consumption.current;
        props.onSubmit(values.gasInCubicMeters, values.normalKwhRate, values.lowKwhRate, values.highKwhRate);
    };
    const consumption = useRef({});
    return (React.createElement("form", { onSubmit: handleSubmit, id: "yearly-usage-form", className: classNames.form },
        React.createElement("div", { className: classNames.container },
            React.createElement(Header, { text: "Vul uw jaarlijks verbruik (kWh) in", size: "large", fontWeight: "bold" }),
            React.createElement("div", { className: classNames.inputWrappers },
                props.hasDoubleMeter === false && (React.createElement(Input, { form: "yearly-usage-form", type: "number", required: true, placeholder: "kWh verbruik", onChange: (e) => (consumption.current.normalKwhRate = +e.currentTarget.value), className: classNames.input })),
                React.createElement("div", { className: classNames.inputsWrapper },
                    props.hasDoubleMeter === true && (React.createElement(React.Fragment, null,
                        React.createElement(Input, { form: "yearly-usage-form", type: "number", required: true, placeholder: "Verbruik laag", onChange: (e) => (consumption.current.lowKwhRate = +e.currentTarget.value), className: classNames.input }),
                        React.createElement(Input, { form: "yearly-usage-form", type: "number", required: true, placeholder: "Verbruik hoog", onChange: (e) => (consumption.current.highKwhRate = +e.currentTarget.value), className: classNames.input }))),
                    props.electricityOnly === false && (React.createElement(Input, { form: "yearly-usage-form", type: "number", required: true, placeholder: "Gas m3", onChange: (e) => (consumption.current.gasInCubicMeters = +e.currentTarget
                            .value) })))),
            React.createElement("div", { className: classNames.buttonsWrapper },
                React.createElement(Button, { text: "Vorige", onClick: () => props.onPreviousClick() }),
                React.createElement(SubmitButton, { className: classNames.submitButton, form: "yearly-usage-form", value: "Volgende" })))));
};
