import React, { useRef } from "react";
import { style } from "typestyle";
const classNames = {
    innerContainer: style({
        display: "flex",
        flexDirection: "column",
        color: "white",
        width: 240
    }),
    logo: style({
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: 20,
        height: 20,
        marginRight: 10
    }),
    lineWrapper: style({
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }),
    phone: style({
        backgroundImage: `url("/svgs/phone-white.svg")`
    }),
    mail: style({
        backgroundImage: `url("/svgs/envelope-white.svg")`
    }),
    text: style({
        textAlign: "right",
        width: "100%"
    }),
    a: style({ color: "white" })
};
export const AddressLine = (props) => {
    const innerClassNames = useRef([classNames.innerContainer, props.className].join(" "));
    return (React.createElement("div", { className: innerClassNames.current },
        React.createElement("div", { className: classNames.lineWrapper },
            React.createElement("div", { className: [classNames.logo, classNames.phone].join(" ") }),
            React.createElement("div", { className: classNames.text },
                React.createElement("a", { className: classNames.a, href: "tel:080 - 123 45 67" }, "080 - 123 45 67"))),
        React.createElement("div", { className: classNames.lineWrapper },
            React.createElement("div", { className: [classNames.logo, classNames.mail].join(" ") }),
            React.createElement("div", { className: classNames.text },
                React.createElement("a", { className: classNames.a, href: "mailto:info@servaas-energie.nl" }, "info@servaas-energie.nl")))));
};
