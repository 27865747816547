import React from "react";
import { style } from "typestyle";
import { Colors } from "../../styling/Colors";
import { InputLabel } from "./inputLabel.element";
const classNames = {
    container: style({
        width: "100%"
    }),
    input: style({
        padding: 10,
        border: "1px solid #E5E5E5",
        fontSize: "1.125rem",
        width: "100%",
        borderBottom: `2px solid white`,
        $nest: {
            "&:hover": {
                borderBottom: `2px solid ${Colors.red}`
            }
        }
    })
};
export const TextArea = (props) => {
    const { name, required, placeholder } = props;
    return (React.createElement("div", { className: [classNames.container, props.className].join(" ") },
        props.showLabel === true && (React.createElement(InputLabel, { required: required }, name)),
        React.createElement("textarea", Object.assign({}, props, { placeholder: placeholder, className: classNames.input }))));
};
