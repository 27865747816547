import React from "react";
import { style } from "typestyle";
import { calculatorUtils } from "../../utils/calculator.utils";
import { Header } from "../elements/header.element";
import { SubmitButton } from "../elements/submitButton";
import { InfoBlock } from "./infoBlock";
const classNames = {
    offerTitle: style({
        marginBottom: "15px",
    }),
    checkWrapper: style({
        display: "flex",
        position: "relative",
        marginBottom: "5px",
    }),
    checkMark: style({
        display: "inline-block",
        backgroundImage: `url('/svgs/check.svg')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        margin: "auto 0",
        width: 20,
        height: 25,
        marginRight: 10,
    }),
    price: style({
        marginTop: 20,
        fontWeight: "bold",
        fontSize: "2rem",
        textAlign: "right",
    }),
    perMonth: style({
        textAlign: "right",
        marginBottom: 20,
    }),
    yearlyBase: style({
        fontSize: "0.8rem",
        marginBottom: 20,
        textAlign: "right",
    }),
    submitButton: style({
        width: "100%",
    }),
};
export const PriceOffer = (_props) => {
    const { energyPrice } = _props;
    const price = calculatorUtils.calculateYearlyPrice(energyPrice);
    return (React.createElement(InfoBlock, null,
        React.createElement("div", { className: classNames.offerTitle },
            React.createElement(Header, { text: "Servaas energie+", fontWeight: "regular", size: "large" })),
        React.createElement("div", { className: classNames.checkWrapper },
            React.createElement("div", { className: classNames.checkMark }),
            " Gedeelde groene stroom"),
        React.createElement("div", { className: classNames.checkWrapper },
            React.createElement("div", { className: classNames.checkMark }),
            " CO\u2082 reductie"),
        React.createElement("div", { className: classNames.checkWrapper },
            React.createElement("div", { className: classNames.checkMark }),
            " Maandelijks opzegbaar"),
        React.createElement("div", { className: classNames.price }, ((price / 12) * 1.21).toLocaleString("nl-NL", {
            style: "currency",
            currency: "EUR",
            maximumFractionDigits: 2,
        })),
        React.createElement("div", { className: classNames.perMonth }, "per maand (btw incl)"),
        React.createElement("div", { className: classNames.yearlyBase },
            (price * 1.21).toLocaleString("nl-NL", {
                style: "currency",
                currency: "EUR",
                maximumFractionDigits: 2,
            }),
            " ",
            "op jaarbasis"),
        _props.showSubscribeButton === true && (React.createElement(SubmitButton, { className: classNames.submitButton, value: "Aanmelden", onClick: () => (_props.onSubmit ? _props.onSubmit() : "") }))));
};
