import React, { useRef, useState } from "react";
import { style } from "typestyle";
import { Colors } from "../../styling/Colors";
import { Dimensions } from "../../styling/Dimensions";
const classNames = {
    container: style({
        $debugName: "container",
        zIndex: 9
    }),
    reviewWrapper: style({
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridGap: "15px",
        width: "100%",
        $nest: {
            "@media screen and (max-width: 800px)": {
                gridTemplateColumns: "1fr 1fr"
            }
        }
    }),
    reviewItem: style({
        display: "flex",
        justifyContent: "space-between",
        padding: Dimensions.padding * 2,
        flexDirection: "column",
        backgroundColor: Colors.grey
    }),
    reviewItemHeader: style({
        fontWeight: "bold",
        paddingBottom: Dimensions.padding
    }),
    reviewItemContent: style({
        paddingBottom: Dimensions.padding * 3,
        maxHeight: 150,
        overflow: "hidden",
        textOverflow: "ellipsis"
    }),
    steps: style({
        $debugName: "step-container",
        width: "100%",
        height: 50,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: `${Dimensions.padding}px 0`
    }),
    stepDot: style({
        cursor: "pointer",
        width: 16,
        height: 16,
        marginLeft: 10,
        marginRight: 10,
        borderRadius: "50%",
        color: Colors.grey,
        backgroundColor: Colors.grey,
        $nest: {
            "&:hover": {
                color: Colors.darkGrey,
                backgroundColor: Colors.darkGrey
            }
        }
    }),
    stepDotActive: style({
        backgroundColor: Colors.darkGrey,
        color: Colors.darkGrey
    })
};
export const ReviewSlider = (props) => {
    const [currentStep, setCurrentStep] = useState(1);
    const itemsPerStep = useRef(3);
    const items = [
        {
            title: "Makkelijk geregeld",
            text: "Makkelijk geregeld! Het overstappen ging zonder moeite",
            author: "Jan",
            city: "Maastricht"
        },
        {
            title: "Veel voordeel",
            text: "Dankzij Servaas Energie besparen wij maandelijks veel!",
            author: "Greetje",
            city: "Eijsden"
        },
        {
            title: "Goede service",
            text: "Goed geholpen door de servicedesk medewerkers!",
            author: "Kees",
            city: "Valkenburg"
        }
    ];
    const stepsArray = [
        ...Array(Math.ceil(items.length / itemsPerStep.current))
    ].map((_, idx) => idx + 1);
    return (React.createElement("div", { className: classNames.container },
        React.createElement("div", { className: classNames.reviewWrapper }, items
            .filter((item, idx) => idx < currentStep * itemsPerStep.current)
            .map((item, idx) => (React.createElement("div", { className: classNames.reviewItem, key: idx },
            React.createElement("div", { className: classNames.reviewItemHeader }, item.title),
            React.createElement("div", { className: classNames.reviewItemContent }, item.text),
            React.createElement("div", null,
                item.author,
                ", ",
                item.city)))))));
};
