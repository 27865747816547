import React from "react";
import { style } from "typestyle";
import { Page } from "../elements/page.element";
const classNames = {
    container: style({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        flex: 1,
        fontSize: "1.2rem",
        textAlign: "center"
    }),
    logo: style({
        backgroundImage: `url('/svgs/header-logo.svg')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        width: "200px",
        height: "140px",
        marginTop: "60px"
    })
};
export const UnderConstruction = (_props) => {
    return (React.createElement(Page, { hideNavbar: true, hideFooter: true },
        React.createElement("div", { className: classNames.container },
            React.createElement("div", null, "Nog even geduld.."),
            React.createElement("div", null, "Servaas Energie is binnenkort online!"),
            React.createElement("div", { className: classNames.logo }))));
};
