import React from "react";
import { style } from "typestyle";
import { InfoBlock } from "../elements/infoBlock";
const classNames = {
    logo: style({
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minWidth: 25,
        minHeight: 25,
        marginRight: 10
    }),
    addressWrapper: style({
        display: "flex",
        flexDirection: "column",
        fontWeight: 500,
        fontSize: "1.125rem"
    }),
    addressLine: style({
        display: "flex",
        marginBottom: 10,
        alignItems: "center"
    }),
    phone: style({
        backgroundImage: `url("/svgs/phone.svg")`
    }),
    mail: style({
        backgroundImage: `url("/svgs/envelope.svg")`
    })
};
export const ContactInfoBlock = (_props) => {
    return (React.createElement(InfoBlock, null,
        React.createElement("div", { className: classNames.addressWrapper },
            React.createElement("div", { className: classNames.addressLine },
                React.createElement("div", { className: [classNames.logo, classNames.phone].join(" ") }),
                React.createElement("a", { href: "tel:080 - 123 45 67" }, "080 - 123 45 67")),
            React.createElement("div", { className: classNames.addressLine },
                React.createElement("div", { className: [classNames.logo, classNames.mail].join(" ") }),
                React.createElement("a", { href: "mailto:info@servaas-energie.nl" }, "info@servaas-energie.nl")),
            React.createElement("div", null))));
};
