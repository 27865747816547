import React, { useState } from "react";
import { style } from "typestyle";
import { solarPanelOptions } from "../../constants/selectOptions";
import { Button } from "../elements/button.element";
import { Header } from "../elements/header.element";
import { InputRadio } from "../elements/inputRadio.elements";
import { SubmitButton } from "../elements/submitButton";
const classNames = {
    container: style({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }),
    buttonsWrapper: style({
        display: "flex",
        flexFlow: "wrap"
    }),
    submitButton: style({
        width: 200
    }),
    form: style({
        height: "100%"
    })
};
export const CalculatorSolarPanelForm = (props) => {
    const [hasSolarPanels, setHasSolarPanels] = useState(null);
    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSubmit(hasSolarPanels);
    };
    return (React.createElement("form", { onSubmit: handleSubmit, id: "solar-panel-form", className: classNames.form },
        React.createElement("div", { className: classNames.container },
            React.createElement(Header, { text: "Heeft u zonnepanelen?", size: "large", fontWeight: "bold" }),
            React.createElement(InputRadio, { name: "solar-panel-radio", form: "solar-panel-form", onChange: (value) => {
                    setHasSolarPanels(value === "yes" ? true : false);
                }, required: true, options: solarPanelOptions }),
            React.createElement("div", { className: classNames.buttonsWrapper },
                React.createElement(Button, { text: "Vorige", onClick: () => props.onPreviousClick() }),
                hasSolarPanels !== null && (React.createElement(SubmitButton, { className: classNames.submitButton, form: "solar-panel-form", value: hasSolarPanels ? "Volgende" : "Verzenden" }))))));
};
