import React from "react";
import { style } from "typestyle";
import { Router } from "../../routing/router";
import { routes } from "../../routing/routes";
import MobileMenu from "../../statics/MobileMenu";
import { Colors } from "../../styling/Colors";
import { Dimensions } from "../../styling/Dimensions";
const classNames = {
    container: style({
        position: "fixed",
        top: 0,
        width: "100%",
        height: Dimensions.navBarHeight,
        backgroundColor: Colors.white,
        $debugName: "container",
        zIndex: 9
    }),
    boxShadow: style({
        boxShadow: "0 2px 4px 0px rgba(0,0,0,.2)"
    }),
    innerContainer: style({
        display: "flex",
        height: "100%",
        margin: "0 auto",
        alignItems: "center",
        maxWidth: Dimensions.maxUIWidth,
        $debugName: "inner-container",
        padding: `0 ${Dimensions.padding}px`
    }),
    left: style({
        marginRight: "auto"
    }),
    right: style({
        marginLeft: "auto",
        $nest: {
            "@media screen and (max-width: 900px)": {
                display: "none"
            }
        }
    }),
    hamburgerMenu: style({
        display: "none",
        backgroundImage: `url('/svgs/hamburger.svg')`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        pointerEvents: "none",
        width: 40,
        height: "100%",
        marginLeft: "auto",
        userSelect: "none",
        $nest: {
            "@media (max-width: 900px)": {
                display: "block",
                pointerEvents: "auto",
                cursor: "pointer"
            }
        }
    }),
    navItem: style({
        fontWeight: 700,
        cursor: "pointer",
        userSelect: "none",
        marginLeft: "65px",
        display: "inline-block"
    }),
    logo: style({
        backgroundImage: `url("/svgs/header-logo.svg")`,
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        width: 180,
        height: 70,
        cursor: "pointer"
    })
};
export const NavigationBar = (props) => {
    const containerClassNames = [
        classNames.container,
        props.isScrolled && classNames.boxShadow
    ]
        .filter(Boolean)
        .join(" ");
    return (React.createElement("div", { className: containerClassNames },
        React.createElement("div", { className: classNames.innerContainer },
            React.createElement("div", { className: classNames.left },
                React.createElement("div", { className: classNames.logo, onClick: () => Router.instance.navigate(routes.home.path) })),
            React.createElement("div", { className: classNames.hamburgerMenu, onClick: () => {
                    MobileMenu.show();
                } }),
            React.createElement("div", { className: classNames.right },
                React.createElement("div", { onClick: () => Router.instance.navigate(routes.home.path), className: classNames.navItem }, "Home"),
                React.createElement("div", { onClick: () => Router.instance.navigate(routes.ourFormula.path), className: classNames.navItem }, "Onze formule"),
                React.createElement("div", { onClick: () => Router.instance.navigate(routes.calculatePrice.path), className: classNames.navItem }, "Prijs berekenen"),
                React.createElement("div", { onClick: () => Router.instance.navigate(routes.contact.path), className: classNames.navItem }, "Contact")))));
};
