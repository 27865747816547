import React from "react";
import { style } from "typestyle";
import { Colors } from "../../styling/Colors";
import { Dimensions } from "../../styling/Dimensions";
const classNames = {
    container: style({
        flex: 1,
        display: "flex",
        height: "100%",
        maxWidth: "100%"
    }),
    steps: style({
        $debugName: "step-container",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: `${Dimensions.padding}px 0`,
        $nest: {
            "@media screen and (max-width: 900px)": {
                display: "none"
            }
        }
    }),
    stepDot: style({
        width: 16,
        height: 16,
        borderRadius: "50%",
        color: Colors.grey,
        backgroundColor: Colors.grey
    }),
    stepDotActive: style({
        backgroundColor: Colors.darkGrey,
        color: Colors.darkGrey
    }),
    questionWrapper: style({
        height: "100%",
        width: "100%",
        paddingLeft: 70,
        $nest: {
            "@media screen and (max-width: 900px)": {
                paddingLeft: 0
            }
        }
    })
};
export const CalculatorStepContainer = (props) => {
    const stepsArray = [...Array(props.totalSteps)].map((_, idx) => idx + 1);
    return (React.createElement("div", { className: classNames.container },
        React.createElement("div", { className: classNames.steps }, stepsArray.map((step) => {
            const isActive = step === props.currentStep;
            const stepDotClasses = [
                classNames.stepDot,
                isActive && classNames.stepDotActive
            ]
                .filter(Boolean)
                .join(" ");
            return React.createElement("div", { className: stepDotClasses, key: step });
        })),
        React.createElement("div", { className: classNames.questionWrapper }, props.children)));
};
