import React, { useRef } from "react";
import { style } from "typestyle";
import { Dimensions } from "../../styling/Dimensions";
import { pdfUtils } from "../../utils/pdf.utils";
import { Input } from "../elements/input.element";
import { InputCheckbox } from "../elements/inputCheckbox";
import { SubmitButton } from "../elements/submitButton";
import { TextArea } from "../elements/textarea.element";
const classNames = {
    container: style({
        height: "100%",
        width: "100%"
    }),
    formWrapper: style({
        backgroundColor: "#EFF1ED",
        padding: `${Dimensions.padding * 2}px`
    }),
    formRow: style({
        display: "flex",
        flexDirection: "row",
        marginBottom: Dimensions.padding,
        $nest: {
            "@media screen and (max-width: 800px)": {
                flexWrap: "wrap"
            }
        }
    }),
    formColumn: style({
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        marginRight: 10
    }),
    acceptLine: style({
        display: "inline-flex",
        marginBottom: Dimensions.padding * 2
    }),
    underline: style({
        textDecoration: "underline",
        cursor: "pointer"
    }),
    requiredLine: style({
        fontSize: "0.9rem",
        fontStyle: "italic",
        margin: `10px 0 20px 0`
    })
};
export const ContactForm = (props) => {
    const contact = {};
    const handleSubmit = useRef((event) => {
        event.preventDefault();
        props.onSubmit(contact);
    });
    return (React.createElement("form", { onSubmit: handleSubmit.current, id: "contact-form" },
        React.createElement("div", { className: classNames.container },
            React.createElement("div", { className: classNames.formWrapper },
                React.createElement("div", { className: classNames.formRow },
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(Input, { type: "text", form: "contact-form", showLabel: true, name: "Naam", required: true, onChange: (e) => (contact.name = e.currentTarget.value) }))),
                React.createElement("div", { className: classNames.formRow },
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(Input, { type: "text", form: "contact-form", showLabel: true, name: "E-mailadres", required: true, onChange: (e) => (contact.email = e.currentTarget.value) })),
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(Input, { type: "text", form: "contact-form", required: true, showLabel: true, name: "Telefoonnummer", onChange: (e) => (contact.phoneNumber = e.currentTarget.value) }))),
                React.createElement("div", { className: classNames.formRow },
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(TextArea, { form: "contact-form", required: true, showLabel: true, name: "Bericht", rows: 10, onChange: (e) => (contact.description = e.currentTarget.value) }))),
                React.createElement("div", { className: classNames.requiredLine }, "Velden met * zijn verplicht"),
                React.createElement("div", { className: classNames.acceptLine },
                    React.createElement(InputCheckbox, { form: "contact-form", name: "privacy-accept", required: true }),
                    React.createElement("span", { style: { marginLeft: 10 } },
                        "Ja, ik ga akkoord met de",
                        " ",
                        React.createElement("span", { className: classNames.underline, onClick: () => pdfUtils.openPrivacy() }, "privacyverklaring"))),
                React.createElement("div", { className: classNames.formRow },
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(SubmitButton, { style: { width: "100%" }, form: "contact-form", value: "Verzenden" })))))));
};
