import React from "react";
import { style } from "typestyle";
import { Colors } from "../../styling/Colors";
const classNames = {
    input: style({
        color: Colors.red,
        textAlign: "center",
        backgroundColor: "transparent",
        minWidth: "200px",
        fontSize: "1.125rem",
        border: `2px solid ${Colors.red}`,
        cursor: "pointer",
        userSelect: "none",
        padding: "10px",
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        appearance: "none",
        $nest: {
            "@media screen and (max-width: 850px)": {
                fontSize: "1rem"
            },
            "&:hover": {
                backgroundColor: Colors.red,
                color: "white",
                transition: "0.3s ease all"
            }
        }
    })
};
export const ButtonInverse = (props) => {
    return (React.createElement("input", Object.assign({}, props, { type: "submit", className: [classNames.input, props.className].join(" ") })));
};
