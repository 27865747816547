import React, { useRef } from "react";
import { style } from "typestyle";
import { solarOrientationOptions } from "../../constants/selectOptions";
import { Button } from "../elements/button.element";
import { Header } from "../elements/header.element";
import { SelectInput } from "../elements/select.element";
import { SubmitButton } from "../elements/submitButton";
const classNames = {
    container: style({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }),
    buttonsWrapper: style({
        display: "flex",
        flexFlow: "wrap"
    }),
    submitButton: style({
        width: 200
    }),
    form: style({
        height: "100%"
    }),
    select: style({
        maxWidth: 350
    })
};
export const CalculatorSolarPanelOrientationForm = (props) => {
    const orientation = useRef(0);
    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSubmit(orientation.current);
    };
    return (React.createElement("form", { onSubmit: handleSubmit, id: "solar-panel-orientation-form", className: classNames.form },
        React.createElement("div", { className: classNames.container },
            React.createElement(Header, { text: "Wat is de orientatie van uw zonnepanelen?", size: "large", fontWeight: "bold" }),
            React.createElement(SelectInput, { options: solarOrientationOptions, className: classNames.select, required: true, form: "solar-panel-orientation-form", onChange: (event) => (orientation.current = +event.currentTarget.value) }),
            React.createElement("div", { className: classNames.buttonsWrapper },
                React.createElement(Button, { text: "Vorige", onClick: () => props.onPreviousClick() }),
                React.createElement(SubmitButton, { className: classNames.submitButton, form: "solar-panel-orientation-form", value: "Verzenden" })))));
};
