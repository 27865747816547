import React from "react";
import { style } from "typestyle";
import { Dimensions } from "../../styling/Dimensions";
import { PageInnerContainer } from "./pageInner.element";
const classNames = {
    mainBlockWrapper: style({
        minHeight: `calc(100vh - ${Dimensions.navBarHeight}px - ${Dimensions.padding * 4}px)`,
        position: "relative",
        display: "flex",
        $debugName: "mainblockWrapper",
        paddingTop: Dimensions.navBarHeight,
        height: "100%",
        width: "100%",
        $nest: {
            "@media screen and (max-width: 1000px)": {
                flexDirection: "column",
                paddingTop: 10
            }
        }
    }),
    contentLeft: style({
        flex: "1 1 200px"
    }),
    contentRight: style({
        flex: "1 1 200px",
        position: "relative",
        $nest: {
            "@media screen and (max-width: 1000px)": {
                margin: `${Dimensions.padding * 3}px 0px`
            }
        }
    }),
    background: style({
        position: "absolute",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        height: `80%`,
        width: `100%`,
        maxWidth: 800,
        zIndex: -1,
        right: "-10%",
        top: 0,
        bottom: 0,
        overflow: "hidden",
        $nest: {
            "@media screen and (max-width: 1200px)": {
                right: "-10%"
            },
            "@media screen and (max-width: 1000px)": {
                left: 0,
                right: 0,
                width: "100%",
                height: "100%",
                margin: "0 auto"
            }
        }
    })
};
export const MainBlock = (_props) => {
    return (React.createElement(PageInnerContainer, { isTopContent: true },
        React.createElement("div", { className: [classNames.mainBlockWrapper, _props.className].join(" ") },
            React.createElement("div", { className: [classNames.contentLeft, _props.contentLeftClassName].join(" ") }, _props.children),
            React.createElement("div", { className: [
                    classNames.contentRight,
                    _props.contentRightClassName
                ].join(" ") },
                React.createElement("div", { style: {
                        backgroundImage: _props.backgroundImageUrl
                            ? `url(${_props.backgroundImageUrl})`
                            : ""
                    }, className: classNames.background })))));
};
