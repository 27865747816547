import React from "react";
import { style } from "typestyle";
import { Colors } from "../../styling/Colors";
import { Dimensions } from "../../styling/Dimensions";
import { pdfUtils } from "../../utils/pdf.utils";
import { AddressLine } from "./addressLine.element";
const classNames = {
    desktopContainer: style({
        width: "100%",
        height: Dimensions.footerHeight,
        backgroundColor: Colors.darkGrey,
        color: "white",
        $nest: {
            "@media screen and (max-width: 850px)": {
                display: "none"
            }
        }
    }),
    innerContainer: style({
        margin: "0 auto",
        height: "100%",
        maxWidth: Dimensions.maxUIWidth,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: Dimensions.padding
    }),
    topBar: style({
        display: "flex",
        justifyContent: "space-between"
    }),
    bottomBar: style({
        display: "flex",
        marginTop: 10,
        justifyContent: "space-between",
        color: "white"
    }),
    logo: style({
        backgroundImage: `url('/svgs/footer-logo.svg')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: 115,
        height: 66
    }),
    contact: style({}),
    line: style({
        width: "100%",
        height: 1,
        border: "1px solid white"
    }),
    copyRight: style({}),
    mobileContainer: style({
        display: "none",
        backgroundColor: Colors.darkGrey,
        paddingTop: Dimensions.padding * 2,
        $nest: {
            "@media screen and (max-width: 850px)": {
                display: "block"
            }
        }
    }),
    mobileLine: style({
        width: "60%",
        height: 1,
        margin: `${Dimensions.padding * 2}px auto`,
        border: "1px solid white"
    }),
    addressMobileWrapper: style({
        display: "flex",
        justifyContent: "center"
    }),
    centerLogo: style({
        width: "100%",
        backgroundPosition: "center",
        marginBottom: 10
    }),
    privacyMobileWrapper: style({
        padding: `${Dimensions.padding * 4}px 0`,
        textAlign: "center",
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }),
    privacy: style({
        marginBottom: 10,
        cursor: "pointer"
    }),
    copyRightMobile: style({
        textAlign: "center",
        width: "100%",
        color: "white",
        paddingBottom: Dimensions.padding
    }),
    pointer: style({
        cursor: "pointer"
    })
};
export const Footer = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames.desktopContainer },
            React.createElement("div", { className: classNames.innerContainer },
                React.createElement("div", { className: classNames.topBar },
                    React.createElement("div", { className: classNames.logo }),
                    React.createElement("div", { className: classNames.contact },
                        React.createElement(AddressLine, null))),
                React.createElement("div", { className: classNames.line }),
                React.createElement("div", { className: classNames.bottomBar },
                    React.createElement("div", { className: classNames.copyRight }, "Copyright 2021 GEB Servaas Energie Co\u00F6peratie U.A."),
                    React.createElement("div", null,
                        React.createElement("span", { className: classNames.pointer, onClick: () => pdfUtils.openPrivacy() }, "Privacyverklaring"),
                        " ",
                        "|",
                        " ",
                        React.createElement("span", { className: classNames.pointer, onClick: () => pdfUtils.openTerms() }, "Algemene voorwaarden"))))),
        React.createElement("div", { className: classNames.mobileContainer },
            React.createElement("div", { className: [classNames.logo, classNames.centerLogo].join(" ") }),
            React.createElement("div", { className: classNames.mobileLine }),
            React.createElement("div", { className: classNames.addressMobileWrapper },
                React.createElement(AddressLine, null)),
            React.createElement("div", { className: classNames.privacyMobileWrapper },
                React.createElement("div", { className: classNames.privacy, onClick: () => pdfUtils.openPrivacy() }, "Privacyverklaring"),
                React.createElement("div", { onClick: () => pdfUtils.openTerms() }, "Algemene voorwaarden")),
            React.createElement("div", { className: classNames.copyRightMobile }, "Copyright Servaas Energie 2021"))));
};
