import React, { useRef } from "react";
import { style } from "typestyle";
import { router } from "../../routing/router";
import { routes } from "../../routing/routes";
import { Colors } from "../../styling/Colors";
import { Dimensions } from "../../styling/Dimensions";
import { ButtonInverse } from "../elements/button-inverse.element";
import { ButtonPrimary } from "../elements/button-primary.element";
import { Header } from "../elements/header.element";
import { MainBlock } from "../elements/mainBlock.element";
import { Page } from "../elements/page.element";
import { PageInnerContainer } from "../elements/pageInner.element";
import { ReviewSlider } from "../elements/reviewSlider.element";
import { SubmitButton } from "../elements/submitButton";
import { htmlUtils } from "../../utils/html";
const redBlockWidth = 400;
const classNames = {
    text: style({
        fontSize: `2.25rem`,
        width: "100%",
        marginBottom: "20px",
    }),
    formWrapper: style({
        display: "inline-flex",
        flexDirection: "column",
    }),
    introWrapper: style({
        maxWidth: "450px",
    }),
    inputs: style({
        display: "flex",
        $debugName: "inputs",
        marginBottom: "30px",
    }),
    input: style({
        marginRight: "10px",
        width: "130px",
        $nest: {
            "@media screen and (max-width: 850px)": {
                width: "95px",
            },
        },
    }),
    grid: style({
        position: "relative",
        width: "100%",
        display: "grid",
        gridTemplateColumns: `auto ${Dimensions.maxUIWidth}px auto`,
        marginBottom: 250,
        $nest: {
            "@media screen and (max-width: 1350px)": {
                gridTemplateColumns: `auto`,
            },
            "@media screen and (max-width: 900px)": {
                display: "none",
            },
        },
    }),
    left: style({
        backgroundColor: "#E0E0E0",
        $nest: {
            "@media screen and (max-width: 1350px)": {
                display: `none`,
            },
        },
    }),
    middleGrid: style({
        position: "relative",
        maxWidth: "100%",
        backgroundColor: "#E0E0E0",
        marginRight: `calc(${redBlockWidth}px / 2)`,
        padding: `${Dimensions.padding * 8}px ${Dimensions.padding * 4}px`,
        paddingRight: `calc((${redBlockWidth}px / 2) + ${Dimensions.padding}px)`,
        $nest: {
            "@media screen and (max-width: 1350px)": {
                marginRight: `calc(${redBlockWidth}px / 2 + ${Dimensions.padding}px)`,
            },
        },
    }),
    right: style({
        $nest: {
            "@media screen and (max-width: 1350px)": {
                display: `none`,
            },
        },
    }),
    secondBlockWhy: style({
        $debugName: "why",
        position: "absolute",
        padding: Dimensions.padding * 3,
        width: redBlockWidth,
        right: -redBlockWidth / 2,
        bottom: 0,
        transform: `translateY(50%)`,
        backgroundColor: Colors.red,
        color: "white",
    }),
    secondBlockWhyCheckMarkWrapper: style({
        display: "flex",
        marginTop: Dimensions.padding * 2,
        fontSize: "1.125rem",
    }),
    secondBlockWhyCheckMark: style({
        backgroundImage: `url('/svgs/check-white.svg')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        margin: "auto 0",
        width: 20,
        height: 25,
        marginRight: 10,
    }),
    secondBlockText: style({
        marginTop: 40,
    }),
    button: style({
        userSelect: "none",
        cursor: "pointer",
        display: "inline-block",
        marginTop: 40,
        padding: `${Dimensions.padding / 2}px ${Dimensions.padding * 4}px`,
        border: `2px solid black`,
        textAlign: "center",
    }),
    mobileBlocks: style({
        display: "none",
        $nest: {
            "@media screen and (max-width: 900px)": {
                display: `block`,
            },
        },
    }),
    mobileDurabilityBlock: style({
        paddingTop: Dimensions.padding * 5,
        paddingBottom: Dimensions.padding * 5,
        backgroundColor: "#E0E0E0",
    }),
    mobileDurabilityText: style({
        marginTop: 40,
    }),
    mobileButton: style({
        userSelect: "none",
        cursor: "pointer",
        width: "100%",
        marginTop: 40,
        padding: `${Dimensions.padding / 2}px ${Dimensions.padding}px`,
        border: `3px solid black`,
        textAlign: "center",
        fontWeight: "bold",
    }),
    mobileWhyBlock: style({
        backgroundColor: Colors.red,
        color: "white",
        paddingTop: Dimensions.padding * 5,
        paddingBottom: Dimensions.padding * 5,
    }),
    reviewWrapper: style({
        $nest: {
            "@media screen and (max-width: 600px)": {
                display: "none",
            },
        },
    }),
    reviewHeader: style({
        marginBottom: 40,
        textAlign: "center",
    }),
    subscribeBlock: style({
        paddingTop: `${Dimensions.padding * 4}px`,
        paddingBottom: `${Dimensions.padding * 4}px`,
        display: "flex",
        flexDirection: "column",
        maxWidth: 600,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    }),
    subscribeHeader: style({
        marginBottom: 40,
    }),
    subscribeButton: style({
        maxWidth: 300,
        width: "100%",
        backgroundColor: Colors.red,
        padding: `${Dimensions.padding / 2}px ${Dimensions.padding * 4}px`,
        color: "white",
        cursor: "pointer",
    }),
};
export const Home = (_props) => {
    const moreInfoBlock = useRef(null);
    const checkMarkLines = [
        "Besparen",
        "CO₂ reductie",
        "Samen energievraag oplossen",
        "Onafhankelijk zijn",
    ].map((text, idx) => {
        return (React.createElement("div", { className: classNames.secondBlockWhyCheckMarkWrapper, key: idx },
            React.createElement("div", { className: classNames.secondBlockWhyCheckMark }),
            React.createElement("div", null, text)));
    });
    const executeScroll = () => {
        htmlUtils.setScrollBehaviour("smooth");
        window.scrollTo(0, window.innerHeight - Dimensions.navBarHeight);
        htmlUtils.setScrollBehaviour("auto");
    };
    /* prettier-ignore */
    const regex = "[1-9][0-9]{3}\\s?[a-zA-Z]{2}";
    return (React.createElement(Page, null,
        React.createElement(MainBlock, { backgroundImageUrl: "/svgs/home.svg" },
            React.createElement("div", { className: classNames.text },
                React.createElement(Header, { text: "Servaas Energie", size: "huge", fontWeight: "regular" }),
                React.createElement(Header, { text: "Bespaar met energie delen", size: "huge", fontWeight: "regular" })),
            React.createElement("div", { className: classNames.introWrapper },
                React.createElement("p", null, "Servaas Energie is een gemeenschappelijk energiebedrijf voor het ruilen van groene- en grijze elektriciteit."),
                React.createElement("p", null, "Bij ruilen zijn beide partijen gelijkwaardig en van elkaar afhankelijk. Door als partijen samen te werken is het mogelijk de CO\u2082 uitstoot te verminderen tegen lagere kosten."),
                React.createElement(ButtonPrimary, { value: "Prijs berekenen", style: { margin: "15px 0", width: "100%" }, onClick: () => router.navigate(routes.calculatePrice.path) }),
                React.createElement(ButtonInverse, { value: "Meer informatie", onClick: executeScroll, style: { width: "100%" } }))),
        React.createElement("div", { className: classNames.grid, ref: moreInfoBlock },
            React.createElement("div", { className: classNames.left }),
            React.createElement("div", { className: classNames.middleGrid },
                React.createElement(Header, { text: "Duurzaam door samenwerking", size: "large", fontWeight: "regular" }),
                React.createElement("div", { className: classNames.secondBlockText }, "Bij Servaas staat samenwerking centraal. Wij verenigen particulieren, kleine bedrijven en grote bedrijven en bieden als uitkomst een voordelig en efficie\u00EBnt energiemodel voor iedereen. Hierdoor wordt de energie niet alleen voordelig opgewekt en verdeeld, maar dit zorgt er ook voor dat u bespaart op uw maandelijkse rekening."),
                React.createElement("div", { className: classNames.button, onClick: () => {
                        router.navigate(routes.ourFormula.path);
                    } }, "Lees ons hele verhaal"),
                React.createElement("div", { className: classNames.secondBlockWhy },
                    React.createElement(Header, { text: "Waarom Servaas Energie", size: "large", fontWeight: "regular" }),
                    checkMarkLines)),
            React.createElement("div", { className: classNames.right })),
        React.createElement("div", { className: classNames.mobileBlocks },
            React.createElement(PageInnerContainer, { className: classNames.mobileDurabilityBlock },
                React.createElement(Header, { text: "Duurzaam door samenwerking", size: "huge", fontWeight: "regular" }),
                React.createElement("div", { className: classNames.mobileDurabilityText }, "Bij Servaas staat samenwerking centraal. Wij verenigen particulieren, kleine bedrijven en grote bedrijven en bieden als uitkomst een voordelig en efficie\u00EBnt energiemodel voor iedereen. Hierdoor wordt de energie niet alleen voordelig opgewekt en verdeeld, maar dit zorgt er ook voor dat u bespaart op uw maandelijkse rekening."),
                React.createElement("div", { className: classNames.mobileButton, onClick: () => {
                        router.navigate(routes.ourFormula.path);
                    } }, "Lees ons hele verhaal")),
            React.createElement(PageInnerContainer, { className: classNames.mobileWhyBlock },
                React.createElement(Header, { text: "Waarom Servaas Energie", size: "huge", fontWeight: "regular" }),
                checkMarkLines)),
        React.createElement(PageInnerContainer, { className: classNames.reviewWrapper },
            React.createElement(Header, { className: classNames.reviewHeader, text: "Wat onze klanten zeggen", fontWeight: "regular", size: "large" }),
            React.createElement(ReviewSlider, null)),
        React.createElement(PageInnerContainer, { className: classNames.subscribeBlock },
            React.createElement(Header, { text: "Benieuwd naar de mogelijkheden?", fontWeight: "regular", size: "large" }),
            React.createElement(Header, { text: "Meld u dan nu direct aan", fontWeight: "regular", size: "large", className: classNames.subscribeHeader }),
            React.createElement(SubmitButton, { value: "Aanmelden", onClick: () => router.navigate(routes.register.path) }))));
};
