import React from "react";
import { style } from "typestyle";
import { Dimensions } from "../../styling/Dimensions";
import { Header } from "../elements/header.element";
import { MainBlock } from "../elements/mainBlock.element";
import { Page } from "../elements/page.element";
import { PageInnerContainer } from "../elements/pageInner.element";
const classNames = {
    mainBlockText: style({
        marginTop: 20
    }),
    background: style({
        position: "absolute",
        backgroundImage: `url('/svgs/cash-register.svg')`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        height: `100%`,
        width: `40%`,
        zIndex: -1,
        right: 0,
        top: 0,
        bottom: 0,
        overflow: "hidden",
        $nest: {
            "@media screen and (max-width: 1000px)": {
                display: "none"
            }
        }
    }),
    secondaryBlock: style({
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 100,
        $nest: {
            "@media screen and (max-width: 1000px)": {
                flexDirection: "column"
            }
        }
    }),
    consumptionImage: style({
        flex: "1 1 200px",
        height: 400,
        marginRight: 20,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundImage: "url('/svgs/consumption.svg')",
        $nest: {
            "@media screen and (max-width: 1000px)": {
                display: "none"
            }
        }
    }),
    consumptionImageMobile: style({
        display: "none",
        flex: "1 1 200px",
        height: 400,
        marginRight: 20,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundImage: "url('/svgs/consumption.svg')",
        $nest: {
            "@media screen and (max-width: 1000px)": {
                display: "block",
                flex: "1 1 400px",
                marginTop: Dimensions.padding * 3
            }
        }
    }),
    secondaryText: style({
        flex: "1 1 200px",
        $nest: {
            "@media screen and (max-width: 1000px)": {
                width: "100%"
            }
        }
    })
};
export const OurFormula = (_props) => {
    return (React.createElement(Page, null,
        React.createElement(MainBlock, { backgroundImageUrl: "/svgs/cash-register.svg" },
            React.createElement(Header, { text: "Onze formule", size: "huge", fontWeight: "regular" }),
            React.createElement("div", { className: classNames.mainBlockText },
                React.createElement("p", null,
                    "Met Servaas neemt u de in- en verkoop van energie in eigen hand. U koopt in op de energiemarkt en verkoopt uw groene elektriciteit aan de buurman. Gas koopt u ook op de energiemarkt. Zonder tussenschakels, met hun kosten en winstmarges. Deze vereenvoudiging maakt Servaas mogelijk.",
                    " "),
                React.createElement("p", null, "Servaas energie is een gemeenschappelijk energie bedrijf, heeft geen aandeelhouders maar leden. De leden delen de winst, deze gaat niet naar de aandeelhouders."),
                React.createElement("p", null, "De doelstelling is geld besparen en de CO\u2082 uitstoot verminderen. Dit kan door op de daken zonnepanelen te leggen en de groene elektriciteit met elkaar te delen."),
                React.createElement("p", null, "Zonnepanelen en windmolens produceren elektriciteit zonder CO\u2082 uitstoot. De groene elektriciteit is vrijgesteld van belastingen en heffingen. Terwijl deze voor grijze elektriciteit aanzienlijk zijn."),
                React.createElement("p", null, "Servaas zorgt ervoor dat de groene elektriciteit binnen het collectief wordt ingezet, het tekort wordt als grijze elektriciteit ingekocht. Zo realiseert Servaas een lagere elektriciteitsprijs voor het collectief. Des te meer leden investeren in zonnepanelen des te lager de prijs wordt."),
                React.createElement("p", null, "Hieronder leest u hoe onze formule werkt in de praktijk."))),
        React.createElement(PageInnerContainer, null,
            React.createElement("div", { className: classNames.secondaryBlock },
                React.createElement("div", { className: classNames.consumptionImage }),
                React.createElement("div", { className: classNames.secondaryText },
                    React.createElement(Header, { size: "huge", fontWeight: "regular", text: "De praktijk" }),
                    React.createElement("p", null, "Een huishouden verbruikt volgens het Nibud gemiddeld 2.990 kWh elektriciteit en 1.470 m3 aardgas. De leden van Servaas, met en zonder zonnepanelen, zorgen samen voor de vergroening van hun elektriciteitsverbruik."),
                    React.createElement("p", null, "Als u zonnepanelen heeft produceren deze overdag groene elektriciteit, meer dan u zelf verbruikt."),
                    React.createElement("p", null, "Van de productie kan en wordt ca. 30% intern verbruikt."),
                    React.createElement("p", null, "De overproductie van 70% verkoopt Servaas aan een huishouden, bedrijf of instelling dat lid is van Servaas. Een ander huishouden koopt hiervan op haar beurt 30%, de resterende 40% koopt een bedrijf of instelling.")),
                React.createElement("div", { className: classNames.consumptionImageMobile })))));
};
