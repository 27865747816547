var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { style } from "typestyle";
import { Colors } from "../../styling/Colors";
import { InputLabel } from "./inputLabel.element";
const classNames = {
    container: style({}),
    input: style({
        padding: 10,
        border: "1px solid #E5E5E5",
        fontSize: "1.125rem",
        width: "100%",
        borderBottom: `2px solid #E5E5E5`,
        $nest: {
            "&:hover": {
                borderBottom: `2px solid ${Colors.red}`
            }
        }
    })
};
export const Input = (props) => {
    const { showLabel, hideAsterix } = props, rest = __rest(props, ["showLabel", "hideAsterix"]);
    return (React.createElement("div", { className: [classNames.container, props.className].join(" ") },
        props.showLabel === true && (React.createElement(InputLabel, { required: rest.required, hideAsterix: props.hideAsterix }, rest.name)),
        React.createElement("input", Object.assign({}, rest, { className: classNames.input }))));
};
