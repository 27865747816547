class EnergyRequestStore {
    constructor() {
        // this.energyPrice = {
        //   currency: Currency.EURO,
        //   electricityYearlyPrice: 3000,
        //   gasYearlyPrice: 200.5,
        //   fixedCostsYearlyPrice: 4320.75,
        //   energyPriceRequestId: "0"
        // };
    }
}
export const energyRequestStore = new EnergyRequestStore();
