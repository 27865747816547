import React from "react";
import { style } from "typestyle";
const classNames = {
    container: style({
        textAlign: "center",
        minWidth: "50px",
        width: 200,
        fontSize: "1.125rem",
        fontWeight: 300,
        cursor: "pointer",
        userSelect: "none",
        padding: "10px 30px",
        textDecoration: "underline"
    })
};
export const Button = (props) => {
    return (React.createElement("div", { onClick: props.onClick, className: classNames.container }, props.text));
};
