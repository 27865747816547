import React, { useState } from "react";
import { style } from "typestyle";
import { electricityOptions } from "../../constants/selectOptions";
import { Button } from "../elements/button.element";
import { Header } from "../elements/header.element";
import { InputRadio } from "../elements/inputRadio.elements";
import { SubmitButton } from "../elements/submitButton";
const classNames = {
    container: style({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }),
    buttonsWrapper: style({
        display: "flex",
        flexFlow: "wrap"
    }),
    submitButton: style({
        width: 200
    }),
    form: style({
        height: "100%"
    })
};
export const CalculatorElectricityGasForm = (props) => {
    const [onlyElectricity, setOnlyElectricity] = useState();
    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSubmit(onlyElectricity);
    };
    return (React.createElement("form", { onSubmit: handleSubmit, id: "gas-electricity", className: classNames.form },
        React.createElement("div", { className: classNames.container },
            React.createElement(Header, { text: "Wat heeft u nodig?", size: "large", fontWeight: "bold" }),
            React.createElement(InputRadio, { name: "gas-electricity", form: "gas-electricity", onChange: (value) => {
                    const onlyElectricity = value === "electricity" ? true : false;
                    setOnlyElectricity(onlyElectricity);
                }, required: true, options: electricityOptions }),
            React.createElement("div", { className: classNames.buttonsWrapper },
                React.createElement(Button, { text: "Vorige", onClick: () => props.onPreviousClick() }),
                props.isCustomRequest === true && (React.createElement(SubmitButton, { className: classNames.submitButton, form: "gas-electricity", value: "Volgende" })),
                !props.isCustomRequest && typeof onlyElectricity !== "undefined" && (React.createElement(SubmitButton, { className: classNames.submitButton, form: "gas-electricity", value: onlyElectricity === false ? "Volgende" : "Verzenden" }))))));
};
