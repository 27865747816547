import React, { useRef, useState } from "react";
import { Gender } from "servaas-energie-packages";
import { style } from "typestyle";
import { genderOptions } from "../../constants/selectOptions";
import { Dimensions } from "../../styling/Dimensions";
import { pdfUtils } from "../../utils/pdf.utils";
import { Input } from "../elements/input.element";
import { InputCheckbox } from "../elements/inputCheckbox";
import { SelectInput } from "../elements/select.element";
import { SubmitButton } from "../elements/submitButton";
const classNames = {
    container: style({
        height: "100%",
        width: "100%"
    }),
    formWrapper: style({
        backgroundColor: "#EFF1ED",
        padding: `${Dimensions.padding * 2}px`
    }),
    formRow: style({
        display: "flex",
        flexDirection: "row",
        // marginBottom: Dimensions.padding,
        $nest: {
            "@media screen and (max-width: 800px)": {
                flexWrap: "wrap"
            }
        }
    }),
    formColumn: style({
        display: "flex",
        flexDirection: "column",
        marginRight: 10,
        flex: 1,
        $nest: {
            "@media screen and (max-width: 800px)": {
                flexBasis: "100%"
            }
        }
    }),
    input: style({
        marginBottom: 15
    }),
    formDoubleColumn: style({
        display: "flex",
        flexDirection: "column",
        flex: 2,
        marginRight: 10,
        $nest: {
            "@media screen and (max-width: 800px)": {
                flexBasis: "100%"
            }
        }
    }),
    hiddenColumn: style({
        display: "flex",
        flexDirection: "column",
        flex: 1,
        marginRight: 10
    }),
    invoiceLine: style({
        display: "inline-flex",
        marginBottom: Dimensions.padding
    }),
    acceptLine: style({
        display: "inline-flex",
        marginBottom: Dimensions.padding * 2
    }),
    underline: style({
        textDecoration: "underline",
        cursor: "pointer"
    }),
    invoiceHeader: style({
        fontSize: "1.3rem",
        marginBottom: 10
    }),
    requiredLine: style({
        fontSize: "0.9rem",
        fontStyle: "italic",
        margin: `10px 0 20px 0`
    })
};
export const RegisterForm = (props) => {
    const user = useRef({
        gender: Gender.MAN
    });
    const [needsExtraInvoiceInputs, setNeedsExtraInvoiceInputs] = useState(false);
    const handleSubmit = useRef((event) => {
        event.preventDefault();
        props.onSubmit(user.current);
    });
    return (React.createElement("form", { onSubmit: handleSubmit.current, id: "register-form" },
        React.createElement("div", { className: classNames.container },
            React.createElement("div", { className: classNames.formWrapper },
                React.createElement("div", { className: classNames.formRow },
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(Input, { type: "text", form: "register-form", showLabel: true, name: "Voornaam", className: classNames.input, onChange: (e) => (user.current.firstName = e.currentTarget.value), required: true }))),
                React.createElement("div", { className: classNames.formRow },
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(Input, { type: "text", form: "register-form", showLabel: true, name: "Tussenvoegsel", className: classNames.input, onChange: (e) => (user.current.lastNamePrefix = e.currentTarget.value) })),
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(Input, { type: "text", form: "register-form", required: true, showLabel: true, name: "Achternaam", className: classNames.input, onChange: (e) => (user.current.lastName = e.currentTarget.value) }))),
                React.createElement("div", { className: classNames.formRow },
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(Input, { type: "text", form: "register-form", className: classNames.input, showLabel: true, name: "Geboortedatum", placeholder: "dd/mm/yyyy", pattern: "^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\\d\\d$", required: true, onBlur: (e) => {
                                const string = e.currentTarget.value;
                                const dateString = string.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
                                const year = +dateString[3];
                                const month = +dateString[2];
                                const day = +dateString[1];
                                user.current.dateOfBirth = new Date(Date.UTC(year, month - 1, day));
                            } })),
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(SelectInput, { form: "register-form", showLabel: true, name: "Geslacht", className: classNames.input, options: genderOptions, required: true, onChange: (e) => {
                                user.current.gender = +e.currentTarget.value;
                            } }))),
                React.createElement("div", { className: classNames.formRow },
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(Input, { type: "email", form: "register-form", showLabel: true, name: "E-mailadres", className: classNames.input, required: true, onChange: (e) => {
                                user.current.email = e.currentTarget.value;
                            } })),
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(Input, { type: "text", form: "register-form", showLabel: true, name: "Telefoonnummer", className: classNames.input, required: true, onChange: (e) => {
                                user.current.phoneNumber = e.currentTarget.value;
                            } }))),
                React.createElement("div", { className: classNames.formRow },
                    React.createElement("div", { className: classNames.formDoubleColumn },
                        React.createElement(Input, { type: "text", form: "register-form", className: classNames.input, showLabel: true, name: "Adres", required: true, onChange: (e) => {
                                user.current.street = e.currentTarget.value;
                            } })),
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(Input, { type: "text", form: "register-form", className: classNames.input, showLabel: true, name: "Huisnummer", required: true, onChange: (e) => {
                                user.current.houseNumber = e.currentTarget.value;
                            } })),
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(Input, { type: "text", form: "register-form", className: classNames.input, showLabel: true, name: "Toevoeging", onChange: (e) => {
                                user.current.houseNumberSuffix = e.currentTarget.value;
                            } }))),
                React.createElement("div", { className: classNames.formRow },
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(Input, { type: "text", form: "register-form", className: classNames.input, showLabel: true, name: "Postcode", pattern: "[1-9][0-9]{3}\\s?[a-zA-Z]{2}", required: true, onChange: (e) => {
                                user.current.zipCode = e.currentTarget.value;
                            } })),
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(Input, { type: "text", form: "register-form", className: classNames.input, showLabel: true, name: "Plaats", required: true, onChange: (e) => {
                                user.current.city = e.currentTarget.value;
                            } }))),
                needsExtraInvoiceInputs === false && (React.createElement("div", { className: classNames.requiredLine }, "Velden met * zijn verplicht")),
                React.createElement("div", { className: classNames.invoiceLine },
                    React.createElement(InputCheckbox, { form: "register-form", name: "invoice-address-checkbox", className: classNames.input, onClick: () => {
                            setNeedsExtraInvoiceInputs(!needsExtraInvoiceInputs);
                        } }),
                    React.createElement("span", { style: { marginLeft: 10 } }, "Mijn factuuradres is niet gelijk aan mijn woonadres")),
                needsExtraInvoiceInputs === true && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classNames.invoiceHeader }, "Factuur gegevens"),
                    React.createElement("div", { className: classNames.formRow },
                        React.createElement("div", { className: classNames.formDoubleColumn },
                            React.createElement(Input, { type: "text", form: "register-form", className: classNames.input, showLabel: true, name: "Adres", required: true, onChange: (e) => {
                                    user.current.invoiceStreet = e.currentTarget.value;
                                } })),
                        React.createElement("div", { className: classNames.formColumn },
                            React.createElement(Input, { type: "text", form: "register-form", className: classNames.input, showLabel: true, name: "Huisnummer", required: true, onChange: (e) => {
                                    user.current.invoiceHouseNumber = e.currentTarget.value;
                                } })),
                        React.createElement("div", { className: classNames.formColumn },
                            React.createElement(Input, { type: "text", form: "register-form", className: classNames.input, showLabel: true, name: "Toevoeging", onChange: (e) => {
                                    user.current.invoiceHouseNumberSuffix =
                                        e.currentTarget.value;
                                } }))),
                    React.createElement("div", { className: classNames.formRow },
                        React.createElement("div", { className: classNames.formColumn },
                            React.createElement(Input, { type: "text", form: "register-form", className: classNames.input, showLabel: true, name: "Postcode", pattern: "[1-9][0-9]{3}\\s?[a-zA-Z]{2}", required: true, onChange: (e) => {
                                    user.current.invoiceZipcode = e.currentTarget.value;
                                } })),
                        React.createElement("div", { className: classNames.formColumn },
                            React.createElement(Input, { type: "text", form: "register-form", className: classNames.input, showLabel: true, name: "Plaats", required: true, onChange: (e) => {
                                    user.current.invoiceCity = e.currentTarget.value;
                                } }))),
                    React.createElement("div", { className: classNames.requiredLine }, "Velden met * zijn verplicht"))),
                React.createElement("div", { className: classNames.acceptLine },
                    React.createElement(InputCheckbox, { form: "register-form", name: "privacy-accept", required: true }),
                    React.createElement("span", { style: { marginLeft: 10 } },
                        "Ja, ik ga akkoord met de",
                        " ",
                        React.createElement("span", { className: classNames.underline, onClick: () => pdfUtils.openPrivacy() }, "privacyverklaring"))),
                React.createElement("div", { className: classNames.formRow },
                    React.createElement("div", { className: classNames.formColumn },
                        React.createElement(SubmitButton, { style: { width: "100%" }, form: "register-form", value: "Aanmelden" })))))));
};
