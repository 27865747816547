import React from "react";
import { style } from "typestyle";
const classNames = {
    small: style({
        fontSize: "0.5rem"
    }),
    medium: style({
        fontSize: "1rem",
        $nest: {
            "@media screen and (max-width: 1000px)": {
                fontSize: "0.8rem"
            }
        }
    }),
    large: style({
        fontSize: "1.5rem",
        $nest: {
            "@media screen and (max-width: 1000px)": {
                fontSize: "1.2rem"
            }
        }
    }),
    huge: style({
        fontSize: "2.25rem",
        $nest: {
            "@media screen and (max-width: 1000px)": {
                fontSize: "1.75rem"
            }
        }
    }),
    light: style({
        fontWeight: 300
    }),
    regular: style({
        fontWeight: 500
    }),
    bold: style({
        fontWeight: 700
    })
};
export const Header = (props) => {
    const classes = [
        classNames[props.size],
        classNames[props.fontWeight],
        props.className && props.className
    ]
        .filter(Boolean)
        .join(" ");
    return React.createElement("div", { className: classes }, props.text);
};
