import React from "react";
import { style } from "typestyle";
import { Colors } from "../../styling/Colors";
import { Dimensions } from "../../styling/Dimensions";
const classNames = {
    container: style({
        display: "flex",
        flexFlow: "wrap",
        $nest: {
            "input[type=radio]:checked + label": {
                backgroundColor: Colors.red,
                color: "white"
            }
        }
    }),
    radio: style({
        display: "none"
    }),
    image: style({
        height: "100%",
        width: 15,
        marginRight: 10,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
    }),
    label: style({
        display: "flex",
        cursor: "pointer",
        width: "100%",
        maxWidth: "250px",
        border: `1px solid ${Colors.grey}`,
        padding: `10px ${Dimensions.padding}px`,
        fontSize: `1.125rem`,
        marginRight: 10,
        justifyContent: "center",
        alignItems: "center",
        $nest: {
            "&:hover": {
                backgroundColor: Colors.red,
                color: "white"
            },
            "&:last-child": {
                marginRight: 0
            }
        }
    })
};
export const InputRadio = (props) => {
    return (React.createElement("div", { className: classNames.container }, props.options.map((option) => {
        var _a;
        return (React.createElement(React.Fragment, { key: option.value },
            React.createElement("input", { id: option.value, className: classNames.radio, type: "radio", name: props.name, required: props.required }),
            React.createElement("label", { htmlFor: option.value, className: classNames.label, onClick: () => props.onChange(option.value) }, (_a = option.imageUrls) === null || _a === void 0 ? void 0 :
                _a.map((url, idx) => (React.createElement("div", { key: idx, className: classNames.image, style: { backgroundImage: `url(${url})` } }))),
                React.createElement("div", null, option.label))));
    })));
};
