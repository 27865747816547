import React, { useState } from "react";
import { style } from "typestyle";
import { housingTypeOptions } from "../../constants/selectOptions";
import { Button } from "../elements/button.element";
import { Header } from "../elements/header.element";
import { SelectInput } from "../elements/select.element";
import { SubmitButton } from "../elements/submitButton";
const classNames = {
    container: style({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }),
    buttonsWrapper: style({
        display: "flex",
        flexFlow: "wrap"
    }),
    submitButton: style({
        width: 200
    }),
    form: style({
        height: "100%"
    }),
    select: style({
        maxWidth: 350
    })
};
export const CalculatorHousingTypeForm = (props) => {
    const [housingType, setHousingType] = useState(props.defaultHousingType);
    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSubmit(housingType);
    };
    return (React.createElement("form", { onSubmit: handleSubmit, id: "housing-type-form", className: classNames.form },
        React.createElement("div", { className: classNames.container },
            React.createElement(Header, { text: "Wat voor type woning heeft u?", size: "large", fontWeight: "bold" }),
            React.createElement(SelectInput, { id: "housing-type-form", form: "housing-type-form", required: true, className: classNames.select, defaultValue: housingType, onChange: (event) => setHousingType(+event.currentTarget.value), options: housingTypeOptions }),
            React.createElement("div", { className: classNames.buttonsWrapper },
                React.createElement(Button, { text: "Vorige", onClick: () => props.onPreviousClick() }),
                React.createElement(SubmitButton, { className: classNames.submitButton, form: "housing-type-form", value: "Verzenden" })))));
};
