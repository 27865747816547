export var SolarPanelOrientation;
(function (SolarPanelOrientation) {
    SolarPanelOrientation[SolarPanelOrientation["ZUID"] = 0] = "ZUID";
    SolarPanelOrientation[SolarPanelOrientation["ZUID_WEST"] = 1] = "ZUID_WEST";
    SolarPanelOrientation[SolarPanelOrientation["ZUID_OOST"] = 2] = "ZUID_OOST";
    SolarPanelOrientation[SolarPanelOrientation["WEST"] = 3] = "WEST";
    SolarPanelOrientation[SolarPanelOrientation["OOST"] = 4] = "OOST";
    SolarPanelOrientation[SolarPanelOrientation["OOST_WEST"] = 5] = "OOST_WEST";
    SolarPanelOrientation[SolarPanelOrientation["NOORD_WEST"] = 6] = "NOORD_WEST";
    SolarPanelOrientation[SolarPanelOrientation["NOORD_OOST"] = 7] = "NOORD_OOST";
    SolarPanelOrientation[SolarPanelOrientation["NOORD"] = 8] = "NOORD";
})(SolarPanelOrientation || (SolarPanelOrientation = {}));
