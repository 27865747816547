import React from "react";
import { style } from "typestyle";
import { energyRequestStore } from "../../stores/energyRequestStore";
import { Router } from "../../routing/router";
import { routes } from "../../routing/routes";
import { Header } from "../elements/header.element";
import { Page } from "../elements/page.element";
import { PriceOffer } from "../elements/priceOffer";
import { MainBlock } from "../elements/mainBlock.element";
const classNames = {
    mainBlock: style({
        paddingTop: 0
    }),
    headerWrapper: style({
        height: 140
    })
};
export const OurOffer = (_props) => {
    if (!energyRequestStore.energyPrice) {
        Router.instance.navigate(routes.contact.path);
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(Page, null,
        React.createElement(MainBlock, { className: classNames.mainBlock, backgroundImageUrl: "/images/house-without-panels.png" },
            React.createElement("div", { className: classNames.headerWrapper },
                React.createElement(Header, { text: "Ons aanbod", size: "huge", fontWeight: "regular" })),
            React.createElement(PriceOffer, { energyPrice: energyRequestStore.energyPrice, showSubscribeButton: true, onSubmit: () => {
                    Router.instance.navigate(routes.register.path);
                } }))));
};
