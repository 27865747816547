import React, { useState } from "react";
import { style } from "typestyle";
import { Header } from "../elements/header.element";
import { SelectInput } from "../elements/select.element";
import { SubmitButton } from "../elements/submitButton";
const classNames = {
    container: style({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }),
    select: style({
        maxWidth: 350
    }),
    submitButton: style({
        width: 200
    }),
    form: style({
        height: "100%"
    })
};
export const CalculatorInitialForm = (props) => {
    const [value, setValue] = useState(props.isCustomRequest ? "custom-request" : "request");
    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSubmit(value === "custom-request" ? true : false);
    };
    return (React.createElement("form", { onSubmit: handleSubmit, id: "step-one", className: classNames.form },
        React.createElement("div", { className: classNames.container },
            React.createElement(Header, { text: "Weet u uw gebruik of wilt u een schatting van ons?", size: "large", fontWeight: "bold" }),
            React.createElement(SelectInput, { id: "help-input", form: "step-one", className: classNames.select, required: true, defaultValue: value, onChange: (event) => {
                    const value = event.currentTarget.value;
                    setValue(value);
                }, options: [
                    { value: "request", label: "Help mij schatten" },
                    { value: "custom-request", label: "Ik weet mijn verbruik" }
                ] }),
            React.createElement(SubmitButton, { className: classNames.submitButton, form: "step-one", value: "Volgende" }))));
};
