import { Gender, HousingType, SolarPanelOrientation } from "servaas-energie-packages";
import { enumUtils } from "../utils/enum.utilts";
export const electricityOptions = [
    {
        label: "alleen stroom",
        value: "electricity",
        imageUrls: ["/svgs/stroom.svg"]
    },
    {
        label: "stroom en gas",
        value: "electricity-gas",
        imageUrls: ["/svgs/stroom.svg", "/svgs/vlam.svg"]
    }
];
export const meterOptions = [
    { label: "enkele meter", value: "single-meter" },
    { label: "dubbele meter", value: "double-meter" }
];
export const residenceOptions = [...Array(6)].map((_, idx) => {
    const citizen = idx + 1;
    return {
        value: citizen.toString(),
        label: citizen + (citizen === 1 ? " bewoner" : " bewoners")
    };
});
export const housingTypeOptions = enumUtils.getSelectOptionsFromEnum(HousingType);
export const solarOrientationOptions = enumUtils.getSelectOptionsFromEnum(SolarPanelOrientation);
export const genderOptions = enumUtils.getSelectOptionsFromEnum(Gender);
export const solarPanelOptions = [
    { value: "yes", label: "ja" },
    { value: "no", label: "nee" }
];
