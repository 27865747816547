var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { style } from "typestyle";
import { Router } from "../routing/router";
import { Colors } from "../styling/Colors";
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Dimensions } from "../styling/Dimensions";
import { routes } from "../routing/routes";
export default class MobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.classNames = {
            container: style({
                display: "flex",
                flexDirection: "column",
                visibility: "hidden",
                position: "fixed",
                width: "100vw",
                height: "100vh",
                top: 0,
                left: 0,
                backgroundColor: Colors.red,
                color: "white",
                zIndex: 10,
                padding: `0px ${Dimensions.padding}px`
            }),
            topArea: style({
                width: "100%",
                height: Dimensions.navBarHeight
            }),
            crossButton: style({
                width: 30,
                height: "100%",
                marginLeft: "auto",
                backgroundImage: `url('/svgs/cross.svg')`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                cursor: "pointer",
                outline: 0
            }),
            menuItems: style({
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }),
            menuItem: style({
                cursor: "pointer",
                fontSize: "2rem",
                textAlign: "center",
                marginTop: "5%",
                padding: `${Dimensions.padding}px 0px`
            })
        };
        this.navMenu = React.createRef();
        MobileMenu.instance = this;
    }
    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }
    static show() {
        return __awaiter(this, void 0, void 0, function* () {
            const _this = MobileMenu.instance;
            _this.navMenu.current.style.opacity = "1";
            _this.navMenu.current.style.pointerEvents = "auto";
            _this.navMenu.current.style.visibility = "visible";
            disableBodyScroll(_this.navMenu.current);
        });
    }
    static hide() {
        return __awaiter(this, void 0, void 0, function* () {
            const _this = MobileMenu.instance;
            _this.navMenu.current.style.opacity = "0";
            _this.navMenu.current.style.pointerEvents = "none";
            _this.navMenu.current.style.visibility = "hidden";
            enableBodyScroll(_this.navMenu.current);
        });
    }
    navigateTo(route) {
        return __awaiter(this, void 0, void 0, function* () {
            Router.instance.navigate(route);
            yield MobileMenu.hide();
        });
    }
    render() {
        return (React.createElement("div", { className: this.classNames.container, ref: this.navMenu },
            React.createElement("div", { className: this.classNames.topArea },
                React.createElement("div", { className: this.classNames.crossButton, onClick: () => MobileMenu.hide() })),
            React.createElement("div", { className: this.classNames.menuItems },
                React.createElement("div", { className: this.classNames.menuItem, onClick: () => this.navigateTo(routes.home.path) }, "Home"),
                React.createElement("div", { className: this.classNames.menuItem, onClick: () => this.navigateTo(routes.ourFormula.path) }, "Onze formule"),
                React.createElement("div", { className: this.classNames.menuItem, onClick: () => this.navigateTo(routes.calculatePrice.path) }, "Prijs berekenen"),
                React.createElement("div", { className: this.classNames.menuItem, onClick: () => this.navigateTo(routes.contact.path) }, "Contact"))));
    }
}
