import { CalculatePrice } from "../components/views/calculatePrice.view";
import { Contact } from "../components/views/contact.view";
import { Home } from "../components/views/home.view";
import { OurFormula } from "../components/views/ourFormula";
import { OurOffer } from "../components/views/ourOffer.view";
import { Register } from "../components/views/register.view";
export const routes = {
    contact: {
        component: Contact,
        path: "/contact",
        exact: true
    },
    home: {
        component: Home,
        path: "/",
        exact: true
    },
    calculatePrice: {
        component: CalculatePrice,
        path: "/prijs-berekening",
        exact: true
    },
    ourFormula: {
        component: OurFormula,
        path: "/onze-formule",
        exact: true
    },
    ourOffer: {
        component: OurOffer,
        path: "/ons-aanbod",
        exact: true
    },
    register: {
        component: Register,
        path: "/aanmelden",
        exact: true
    }
};
