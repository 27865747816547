export const pdfUtils = new (class PdfUtils {
    /**
     * Returns a string with the first letter capitalized
     *
     */
    openPrivacy() {
        window.open("/pdfs/privacy-statement-servaas-energie.pdf", "_blank", "fullscreen=yes");
    }
    openTerms() {
        window.open("/pdfs/algemeen-leveringsvoorwaarden-2020.pdf", "_blank", "fullscreen=yes");
    }
})();
