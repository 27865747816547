var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import { style } from "typestyle";
import { energyRequestStore } from "../../stores/energyRequestStore";
import { energyPriceRequestService } from "../../services/energy-price-request.service";
import { userService } from "../../services/user.service";
import Notification from "../../statics/Notification";
import { Dimensions } from "../../styling/Dimensions";
import { ContactInfoBlock } from "../elements/contactInfoBlock";
import { Header } from "../elements/header.element";
import { Page } from "../elements/page.element";
import { PageInnerContainer } from "../elements/pageInner.element";
import { PriceOffer } from "../elements/priceOffer";
import { RegisterForm } from "../forms/register.form";
const classNames = {
    header: style({
        height: 140,
        $nest: {
            "@media screen and (max-width: 1000px)": {
                height: 100
            }
        }
    }),
    pageWrapper: style({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        minHeight: `calc(100vh - (${Dimensions.navBarHeight}px + ${Dimensions.footerHeight}px) )`,
        $nest: {
            "@media screen and (max-width: 1000px)": {
                flexWrap: "wrap"
            }
        }
    }),
    mainBlock: style({
        $debugName: "mainblock",
        height: "100%",
        width: "60%",
        $nest: {
            "@media screen and (max-width: 1000px)": {
                width: "100%"
            }
        }
    }),
    contactBlock: style({
        height: "100%",
        width: "30%",
        $nest: {
            "@media screen and (max-width: 1000px)": {
                width: "100%",
                padding: `${Dimensions.padding * 4}px 0px`
            }
        }
    })
};
export const Register = (_props) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    return (React.createElement(Page, null,
        React.createElement(PageInnerContainer, { isTopContent: true },
            React.createElement("div", { className: classNames.pageWrapper },
                React.createElement("div", { className: classNames.mainBlock },
                    React.createElement(Header, { text: "Aanmelden", size: "huge", fontWeight: "regular", className: classNames.header }),
                    isSubmitted === false && (React.createElement(RegisterForm, { onSubmit: (user) => __awaiter(void 0, void 0, void 0, function* () {
                            try {
                                const { data: createdUser } = yield userService.post(user);
                                if (user && energyRequestStore.energyRequest) {
                                    const { energyRequest } = energyRequestStore;
                                    energyRequest.userId = createdUser.id;
                                    energyPriceRequestService.patch(energyRequest);
                                }
                                setIsSubmitted(true);
                                window.scrollTo(0, 0);
                            }
                            catch (e) {
                                Notification.show({
                                    title: "Er ging iets niet goed",
                                    text: "Controleer alle velden, emailadressen mogen maar eenmaal gebruikt worden!",
                                    type: "error",
                                    hideAfterSeconds: 10
                                });
                            }
                        }) })),
                    isSubmitted === true && (React.createElement("span", null, "Bedankt, voor uw aanmelding! We nemen zo spoedig mogelijk contact met u op!"))),
                React.createElement("div", { className: classNames.contactBlock },
                    React.createElement(Header, { text: energyRequestStore.energyPrice ? "Ons aanbod" : "Contact", size: "huge", fontWeight: "regular", className: classNames.header }),
                    energyRequestStore.energyPrice && (React.createElement(PriceOffer, { energyPrice: energyRequestStore.energyPrice, showSubscribeButton: false })),
                    !energyRequestStore.energyPrice && React.createElement(ContactInfoBlock, null))))));
};
