import React, { useRef, useState } from "react";
import { style } from "typestyle";
import { Dimensions } from "../../styling/Dimensions";
import { CalculatorStepContainer } from "./calculatorStepContainer.element";
import { CalculatorInitialForm } from "../forms/calculatorStepInitial.form";
import { CalculatorResidenceForm } from "../forms/calculatorResidence.form";
import { CalculatorElectricityGasForm } from "../forms/calculatorElectricityGas.form";
import { CalculatorHousingTypeForm } from "../forms/calculatorHousingType.form";
import { HousingType } from "../../enums/housingType.enum";
import { CalculatorMeterTypeForm } from "../forms/calculatorMeterType.form";
import { CalculatorYearlyUsageForm } from "../forms/calculatorYearlyUsage.form";
import { CalculatorSolarPanelForm } from "../forms/calculatorSolarPanel.form";
import { CalculatorSolarPanelDetailsForm } from "../forms/calculatorSolarPanelDetails.form";
import { CalculatorSolarPanelWattPerPanelForm } from "../forms/calculatorSolarPanelWattPerPanel.form";
import { CalculatorSolarPanelYearForm } from "../forms/calculatorSolarPanelYear.form";
import { CalculatorSolarPanelOrientationForm } from "../forms/calculatorSolarPanelOrientation.form";
const classNames = {
    container: style({
        height: 350,
        maxWidth: 796,
        width: "100%",
        padding: `${Dimensions.padding * 3}px 0px`
    })
};
export const Calculator = (props) => {
    const totalSteps = useRef(5);
    const [step, setStep] = useState(1);
    const [isCustomRequest, setIsCustomRequest] = useState(false);
    const [hasDoubleMeter, setHasDoubleMeter] = useState(null);
    const [hasSolarPanels, setHasSolarPanels] = useState(null);
    let energyRequest = useRef({});
    const getRemainingSteps = useRef((currentStep, isCustomRequest) => {
        if (isCustomRequest && currentStep > 5) {
            return 4;
        }
        else if (isCustomRequest && currentStep <= 5) {
            return 5;
        }
        else {
            return 4;
        }
    });
    return (React.createElement("div", { className: classNames.container },
        React.createElement(CalculatorStepContainer, { totalSteps: getRemainingSteps.current(step, isCustomRequest), currentStep: step > 5 ? step % totalSteps.current : step, onClick: (step) => setStep(step) },
            step === 1 && (React.createElement(CalculatorInitialForm, { isCustomRequest: isCustomRequest, onSubmit: (isCustom) => {
                    setIsCustomRequest(isCustom);
                    energyRequest.current.knowsConsumption = isCustom;
                    setStep(2);
                } })),
            isCustomRequest === false && (React.createElement(React.Fragment, null,
                step === 2 && (React.createElement(CalculatorResidenceForm, { defaultResidence: 1, onSubmit: (residence) => {
                        energyRequest.current.residenceAmount = residence;
                        setStep(3);
                    }, onPreviousClick: () => {
                        energyRequest.current.residenceAmount = null;
                        setStep(1);
                    } })),
                step === 3 && (React.createElement(CalculatorElectricityGasForm, { onSubmit: (electricityOnly) => {
                        energyRequest.current.electricityOnly = electricityOnly;
                        // if electricityOnly we can immediately start calculating
                        // otherwise we still need one more step
                        electricityOnly
                            ? props.onSubmit(energyRequest.current)
                            : setStep(4);
                    }, onPreviousClick: () => {
                        //reset values
                        energyRequest.current.electricityOnly = null;
                        setStep(2);
                    } })),
                step === 4 && (React.createElement(CalculatorHousingTypeForm, { onSubmit: (housingType) => {
                        energyRequest.current.housingType = housingType;
                        props.onSubmit(energyRequest.current);
                    }, defaultHousingType: HousingType.FLAT, onPreviousClick: () => {
                        //reset values
                        energyRequest.current.housingType = null;
                        setStep(3);
                    } })))),
            isCustomRequest === true && (React.createElement(React.Fragment, null,
                step === 2 && (React.createElement(CalculatorElectricityGasForm, { isCustomRequest: isCustomRequest, onSubmit: (electricityOnly) => {
                        // set electricity only in the custom request object
                        energyRequest.current.electricityOnly = electricityOnly;
                        setStep(3);
                    }, onPreviousClick: () => {
                        // reset values
                        energyRequest.current.electricityOnly = null;
                        setStep(1);
                    } })),
                step === 3 && (React.createElement(CalculatorMeterTypeForm, { onSubmit: (hasDoubleMeter) => {
                        setHasDoubleMeter(hasDoubleMeter);
                        setStep(4);
                    }, onPreviousClick: () => {
                        //reset values
                        setHasDoubleMeter(null);
                        setStep(2);
                    } })),
                step === 4 && (React.createElement(CalculatorYearlyUsageForm, { hasDoubleMeter: hasDoubleMeter, electricityOnly: energyRequest.current.electricityOnly, onSubmit: (gas, normal, low, high) => {
                        if (gas)
                            energyRequest.current.gasInCubicMeters = gas;
                        if (typeof normal === "number")
                            energyRequest.current.normalKwhRate = normal;
                        if (typeof low === "number")
                            energyRequest.current.lowKwhRate = low;
                        if (typeof high === "number")
                            energyRequest.current.highKwhRate = high;
                        setStep(5);
                    }, onPreviousClick: () => {
                        // reset values
                        energyRequest.current.gasInCubicMeters = null;
                        energyRequest.current.normalKwhRate = null;
                        energyRequest.current.lowKwhRate = null;
                        energyRequest.current.highKwhRate = null;
                        setStep(3);
                    } })),
                step === 5 && (React.createElement(CalculatorSolarPanelForm, { onSubmit: (hasSolarPanels) => {
                        setHasSolarPanels(hasSolarPanels);
                        // if has solar panels user needs to enter information in a next step
                        // otherwise submit the form
                        hasSolarPanels
                            ? setStep(6)
                            : props.onSubmit(energyRequest.current);
                    }, onPreviousClick: () => {
                        // reset values
                        setHasSolarPanels(null);
                        setStep(4);
                    } })),
                step === 6 && hasSolarPanels === true && (React.createElement(CalculatorSolarPanelDetailsForm, { hasDoubleMeter: hasDoubleMeter, onSubmit: (lowKwh, normalKwh, highKwh) => {
                        energyRequest.current.solarPanelLowKwh = lowKwh;
                        energyRequest.current.solarPanelNormalKwh = normalKwh;
                        energyRequest.current.solarPanelHighKwh = highKwh;
                        setStep(7);
                    }, onPreviousClick: () => {
                        // reset values
                        energyRequest.current.solarPanelLowKwh = null;
                        energyRequest.current.solarPanelHighKwh = null;
                        setStep(5);
                    } })),
                step === 7 && (React.createElement(CalculatorSolarPanelWattPerPanelForm, { onSubmit: (watts, panelAmount) => {
                        energyRequest.current.solarPanelWattsPerPanel = watts;
                        energyRequest.current.solarPanelAmount = panelAmount;
                        setStep(8);
                    }, onPreviousClick: () => {
                        //reset values
                        energyRequest.current.solarPanelWattsPerPanel = null;
                        energyRequest.current.solarPanelAmount = null;
                        setStep(6);
                    } })),
                step === 8 && (React.createElement(CalculatorSolarPanelYearForm, { onSubmit: (year) => {
                        energyRequest.current.solarPanelYear = year;
                        setStep(9);
                    }, onPreviousClick: () => {
                        //reset values
                        energyRequest.current.solarPanelYear = null;
                        setStep(7);
                    } })),
                step === 9 && (React.createElement(CalculatorSolarPanelOrientationForm, { onSubmit: (orientation) => {
                        energyRequest.current.solarPanelOrientation = orientation;
                        props.onSubmit(energyRequest.current);
                    }, onPreviousClick: () => {
                        //reset values
                        energyRequest.current.solarPanelOrientation = null;
                        setStep(8);
                    } })))))));
};
