import { stringUtils } from "./string.utils";
export const enumUtils = new (class EnumUtils {
    getSelectOptionsFromEnum(enumArray) {
        const selectOptions = [];
        if (enumArray) {
            for (const enumMember in enumArray) {
                const isValueProperty = parseInt(enumMember, 10) >= 0;
                if (isValueProperty) {
                    selectOptions.push({
                        value: enumMember,
                        label: stringUtils
                            .capitalizeFirstLetter(enumArray[enumMember])
                            .replace(/_/g, " ")
                    });
                }
            }
        }
        return selectOptions;
    }
})();
