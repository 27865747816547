import React, { useState } from "react";
import { style } from "typestyle";
import { meterOptions } from "../../constants/selectOptions";
import { Button } from "../elements/button.element";
import { Header } from "../elements/header.element";
import { InputRadio } from "../elements/inputRadio.elements";
import { SubmitButton } from "../elements/submitButton";
const classNames = {
    container: style({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }),
    buttonsWrapper: style({
        display: "flex",
        flexFlow: "wrap"
    }),
    submitButton: style({
        width: 200
    }),
    form: style({
        height: "100%"
    })
};
export const CalculatorMeterTypeForm = (props) => {
    const [hasDoubleMeter, setHasDoubleMeter] = useState();
    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSubmit(hasDoubleMeter);
    };
    return (React.createElement("form", { onSubmit: handleSubmit, id: "meter-type", className: classNames.form },
        React.createElement("div", { className: classNames.container },
            React.createElement(Header, { text: "Welke meter heeft u?", size: "large", fontWeight: "bold" }),
            React.createElement(InputRadio, { name: "meter-type-input", form: "meter-type", onChange: (value) => {
                    setHasDoubleMeter(value === "double-meter" ? true : false);
                }, required: true, options: meterOptions }),
            React.createElement("div", { className: classNames.buttonsWrapper },
                React.createElement(Button, { text: "Vorige", onClick: () => props.onPreviousClick() }),
                React.createElement(SubmitButton, { className: classNames.submitButton, form: "meter-type", value: "Volgende" })))));
};
