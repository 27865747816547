import React, { useRef } from "react";
import { style } from "typestyle";
import { Dimensions } from "../../styling/Dimensions";
import { Button } from "../elements/button.element";
import { Header } from "../elements/header.element";
import { Input } from "../elements/input.element";
import { SubmitButton } from "../elements/submitButton";
const classNames = {
    container: style({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }),
    inputWrapper: style({
        display: "flex",
        padding: `${Dimensions.padding} 0`
    }),
    buttonsWrapper: style({
        display: "flex",
        flexFlow: "wrap"
    }),
    submitButton: style({
        width: 200
    }),
    form: style({
        height: "100%"
    }),
    wpInput: style({
        marginRight: 10
    })
};
export const CalculatorSolarPanelWattPerPanelForm = (props) => {
    const wattPerPanel = useRef(null);
    const panelAmount = useRef(null);
    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSubmit(wattPerPanel.current, panelAmount.current);
    };
    return (React.createElement("form", { onSubmit: handleSubmit, id: "solar-panel-watt-form", className: classNames.form },
        React.createElement("div", { className: classNames.container },
            React.createElement(Header, { text: "Wat is het wattpiek (Wp) per paneel en hoeveel panelen heeft u?", size: "large", fontWeight: "bold" }),
            React.createElement("div", { className: classNames.inputWrapper },
                React.createElement(Input, { type: "number", name: "solar-panel-watt", form: "solar-panel-watt-form", placeholder: "Wp per paneel", className: classNames.wpInput, onChange: (e) => {
                        wattPerPanel.current = +e.currentTarget.value;
                    }, required: true }),
                React.createElement(Input, { type: "number", name: "solar-panel-watt", form: "solar-panel-watt-form", placeholder: "Aantal panelen", onChange: (e) => {
                        panelAmount.current = +e.currentTarget.value;
                    }, required: true })),
            React.createElement("div", { className: classNames.buttonsWrapper },
                React.createElement(Button, { text: "Vorige", onClick: () => props.onPreviousClick() }),
                React.createElement(SubmitButton, { className: classNames.submitButton, form: "solar-panel-watt-form", value: "Volgende" })))));
};
