var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { style } from "typestyle";
import { energyRequestStore } from "../../stores/energyRequestStore";
import { Router } from "../../routing/router";
import { routes } from "../../routing/routes";
import { energyPriceRequestService } from "../../services/energy-price-request.service";
import { Dimensions } from "../../styling/Dimensions";
import { Calculator } from "../elements/calculator.element";
import { Header } from "../elements/header.element";
import { Page } from "../elements/page.element";
import { MainBlock } from "../elements/mainBlock.element";
const classNames = {
    mainBlock: style({
        height: `calc(100vh - ${Dimensions.navBarHeight}px)`,
        position: "relative",
        $debugName: "mainblock",
        paddingTop: Dimensions.navBarHeight,
        width: "100%"
    }),
    calculatorWrapper: style({
        height: "100%",
        width: "100%",
        $nest: {
            "@media screen and (max-width: 850px)": {
                width: "100%"
            }
        }
    }),
    text: style({
        fontSize: `2.25rem`,
        width: "100%",
        paddingTop: `calc((100vh - ${Dimensions.navBarHeight}px) / 6)`,
        marginBottom: "20px"
    }),
    background: style({
        position: "absolute",
        backgroundImage: `url('/svgs/house-with-panels.svg')`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        height: `100%`,
        width: `50%`,
        zIndex: -1,
        right: "-5%",
        top: 0,
        bottom: 0,
        $nest: {
            "@media screen and (max-width: 850px)": {
                display: "none"
            }
        }
    }),
    mainBlockContentLeft: style({
        flex: "2 1 200px"
    })
};
export const CalculatePrice = (_props) => {
    return (React.createElement(Page, null,
        React.createElement(MainBlock, { contentLeftClassName: classNames.mainBlockContentLeft, backgroundImageUrl: "/svgs/house-with-panels.svg" },
            React.createElement("div", { className: classNames.calculatorWrapper },
                React.createElement(Header, { text: "Bereken uw prijs", fontWeight: "regular", size: "huge" }),
                React.createElement(Calculator, { onSubmit: (req) => __awaiter(void 0, void 0, void 0, function* () {
                        try {
                            const { data: energyRequest } = yield energyPriceRequestService.post(req);
                            energyRequestStore.energyRequest = energyRequest;
                            const { data: energyPrice } = yield energyPriceRequestService.getPrice(energyRequest.id);
                            energyRequestStore.energyPrice = energyPrice;
                            Router.instance.navigate(routes.ourOffer.path);
                        }
                        catch (e) {
                            Router.instance.navigate(routes.ourOffer.path);
                        }
                    }) })))));
};
