import React from "react";
import { style } from "typestyle";
const classNames = {
    container: style({
        fontSize: "1.125rem",
        whiteSpace: "nowrap",
        marginBottom: 8
    }),
    required: style({
        marginLeft: "5px"
    })
};
export const InputLabel = (props) => {
    const { children, required, hideAsterix } = props;
    return (React.createElement("div", { className: classNames.container }, required === true && hideAsterix !== true ? `${children} *` : children));
};
