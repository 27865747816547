import React, { useRef } from "react";
import { style } from "typestyle";
import { Button } from "../elements/button.element";
import { Header } from "../elements/header.element";
import { Input } from "../elements/input.element";
import { SubmitButton } from "../elements/submitButton";
const classNames = {
    container: style({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }),
    buttonsWrapper: style({
        display: "flex",
        flexFlow: "wrap"
    }),
    submitButton: style({
        width: 200
    }),
    form: style({
        height: "100%"
    }),
    input: style({
        maxWidth: 250
    })
};
export const CalculatorSolarPanelYearForm = (props) => {
    const year = useRef(null);
    const handleSubmit = (event) => {
        event.preventDefault();
        props.onSubmit(year.current);
    };
    return (React.createElement("form", { onSubmit: handleSubmit, id: "solar-panel-year-form", className: classNames.form },
        React.createElement("div", { className: classNames.container },
            React.createElement(Header, { text: "Wat is het installatiejaar van de panelen?", size: "large", fontWeight: "bold" }),
            React.createElement(Input, { type: "number", name: "solar-panel-watt", form: "solar-panel-year-form", placeholder: "installatiejaar", min: 1990, max: new Date().getFullYear(), onChange: (e) => {
                    year.current = +e.currentTarget.value;
                }, className: classNames.input, required: true }),
            React.createElement("div", { className: classNames.buttonsWrapper },
                React.createElement(Button, { text: "Vorige", onClick: () => props.onPreviousClick() }),
                React.createElement(SubmitButton, { className: classNames.submitButton, form: "solar-panel-year-form", value: "Volgende" })))));
};
