import * as React from "react";
import { Switch, Route } from "react-router-dom";
import { UnderConstruction } from "../components/views/under-construction";
import { router, Router } from "../routing/router";
import { routes } from "../routing/routes";
import MobileMenu from "../statics/MobileMenu";
import Notification from "../statics/Notification";
export class Main extends React.Component {
    constructor(props) {
        super(props);
        const url = new URLSearchParams(window.location.search);
        if (url.get("live") === "1")
            localStorage.setItem("live", "1");
    }
    render() {
        if (!localStorage.getItem("live"))
            return React.createElement(UnderConstruction, null);
        return (React.createElement("div", null,
            React.createElement(Router.Element, { history: router.history },
                React.createElement(Switch, null, Object.keys(routes).map((key) => (React.createElement(Route, { key: key, component: routes[key].component, path: routes[key].path, exact: routes[key].exact }))))),
            React.createElement(MobileMenu, null),
            React.createElement(Notification, null)));
    }
}
